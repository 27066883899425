import Workspace from "@/models/workspace";
import paths from "@/utils/paths";
import showToast from "@/utils/toast";
import { Plus, CircleNotch, Trash } from "@phosphor-icons/react";
import { useEffect, useState, useCallback, useRef } from "react";
import ThreadItem from "./ThreadItem";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const THREAD_RENAME_EVENT = "renameThread";

export default function ThreadContainer({ workspace }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { threadSlug = null } = useParams();
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteTimeoutRef = useRef(null);

  const chatHandler = useCallback((event) => {
    const { threadSlug, newName } = event.detail;
    setThreads((prevThreads) =>
      prevThreads.map((thread) => {
        if (thread.slug === threadSlug) {
          return { ...thread, name: newName };
        }
        return thread;
      })
    );
  }, []);

  useEffect(() => {
    window.addEventListener(THREAD_RENAME_EVENT, chatHandler);
    return () => {
      window.removeEventListener(THREAD_RENAME_EVENT, chatHandler);
    };
  }, [chatHandler]);

  useEffect(() => {
    let mounted = true;
    async function fetchThreads() {
      if (!workspace.slug) return;

      try {
        const { threads } = await Workspace.threads.all(workspace.slug);
        if (!mounted) return;

        setLoading(false);
        setThreads(threads);

        // If there are no threads, automatically create a new one
        if (threads.length === 0) {
          const { thread, error } = await Workspace.threads.new(workspace.slug);
          if (!mounted) return;

          if (!error && thread) {
            // Recheck that no threads were created while we were creating this one
            const { threads: latestThreads } = await Workspace.threads.all(
              workspace.slug
            );
            if (!mounted) return;

            if (latestThreads.length === 0) {
              navigate(paths.workspace.thread(workspace.slug, thread.slug), {
                replace: true,
              });
            } else {
              setThreads(latestThreads);
            }
          }
        }
      } catch (error) {
        if (!mounted) return;
        setLoading(false);
        showToast(t("errors.thread-fetch-failed"), "error", { clear: true });
      }
    }

    fetchThreads();
    return () => {
      mounted = false;
    };
  }, [workspace.slug, t, navigate]);

  // Enable toggling of bulk-deletion by holding meta-key (ctrl on win and cmd/fn on others)
  useEffect(() => {
    let keyDownTimeout;

    const handleKeyDown = (event) => {
      if (["Control", "Meta"].includes(event.key)) {
        setCtrlPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (["Control", "Meta"].includes(event.key)) {
        setCtrlPressed(false);
        // when toggling, unset bulk progress so
        // previously marked threads that were never deleted
        // come back to life.
        setThreads((prev) =>
          prev.map((t) => {
            return { ...t, deleted: false };
          })
        );
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setCtrlPressed(false);
        setThreads((prev) =>
          prev.map((t) => {
            return { ...t, deleted: false };
          })
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (keyDownTimeout) clearTimeout(keyDownTimeout);
    };
  }, []);

  const toggleForDeletion = useCallback((id) => {
    setThreads((prev) =>
      prev.map((t) => {
        if (t.id !== id) return t;
        return { ...t, deleted: !t.deleted };
      })
    );
  }, []);

  const handleDeleteAll = useCallback(async () => {
    try {
      setIsDeleting(true);
      const slugs = threads
        .filter((t) => t.deleted === true)
        .map((t) => t.slug);
      const success = await Workspace.threads.deleteBulk(workspace.slug, slugs);

      if (success) {
        setThreads((prev) => prev.filter((t) => !t.deleted));
        showToast(t("success.threads-deleted"), "success", { clear: true });
      } else {
        throw new Error("Failed to delete threads");
      }
    } catch (error) {
      showToast(t("errors.delete-threads-failed"), "error", { clear: true });
      // Reset deleted state on error
      setThreads((prev) => prev.map((t) => ({ ...t, deleted: false })));
    } finally {
      setIsDeleting(false);
    }
  }, [workspace.slug, threads, t]);

  const removeThread = useCallback((threadId) => {
    setThreads((prev) =>
      prev.map((_t) => {
        if (_t.id !== threadId) return _t;
        return { ..._t, deleted: true };
      })
    );

    // Clear any existing timeout
    if (deleteTimeoutRef.current) {
      clearTimeout(deleteTimeoutRef.current);
    }

    // Set new timeout and store the reference
    deleteTimeoutRef.current = setTimeout(() => {
      setThreads((prev) => prev.filter((t) => !t.deleted));
      deleteTimeoutRef.current = null;
    }, 500);
  }, []);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (deleteTimeoutRef.current) {
        clearTimeout(deleteTimeoutRef.current);
      }
    };
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col bg-pulse w-full h-10 items-center justify-center">
        <p className="text-xs animate-pulse normal-text">
          {t("sidebar.thread.load-thread")}
        </p>
      </div>
    );
  }

  const activeThreadIdx = !!threads.find(
    (thread) => thread?.slug === threadSlug
  )
    ? threads.findIndex((thread) => thread?.slug === threadSlug)
    : -1;

  return (
    <div className="flex flex-col" role="list" aria-label="Threads">
      {threads.map((thread, i) => (
        <ThreadItem
          key={thread.slug}
          idx={i}
          ctrlPressed={ctrlPressed}
          toggleMarkForDeletion={toggleForDeletion}
          activeIdx={activeThreadIdx}
          isActive={activeThreadIdx === i}
          workspace={workspace}
          onRemove={removeThread}
          thread={thread}
          hasNext={i !== threads.length - 1}
        />
      ))}
      <DeleteAllThreadButton
        ctrlPressed={ctrlPressed}
        threads={threads}
        onDelete={handleDeleteAll}
        isDeleting={isDeleting}
      />
      <NewThreadButton workspace={workspace} />
    </div>
  );
}

function NewThreadButton({ workspace }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    const { thread, error } = await Workspace.threads.new(workspace.slug);
    if (!!error) {
      showToast(`Could not create thread - ${error}`, "error", { clear: true });
      setLoading(false);
      return;
    }
    window.location.replace(
      paths.workspace.thread(workspace.slug, thread.slug)
    );
  };

  return (
    <button
      onClick={onClick}
      className="w-80% relative flex ml-4 h-[40px] mt-1 items-center border-none shadow-bg rounded-lg"
    >
      <div className="flex w-full gap-x-2 items-center pl-2">
        <div className="primary-bg p-2 rounded-[6px] h-[24px] w-[24px] flex items-center justify-center">
          {loading ? (
            <CircleNotch
              weight="bold"
              size={14}
              className="shrink-0 animate-spin normal-text"
            />
          ) : (
            <Plus weight="bold" size={14} className="shrink-0 text-white" />
          )}
        </div>

        {loading ? (
          <p className="text-left normal-text text-sm">
            {t("sidebar.thread.starting-thread")}
          </p>
        ) : (
          <p className="text-left normal-text text-sm">
            {t("sidebar.thread.thread")}
          </p>
        )}
      </div>
    </button>
  );
}

function DeleteAllThreadButton({ ctrlPressed, threads, onDelete, isDeleting }) {
  const { t } = useTranslation();

  if (!ctrlPressed || threads.filter((t) => t.deleted).length === 0)
    return null;
  return (
    <button
      type="button"
      onClick={onDelete}
      className="w-full relative flex h-[40px] items-center border-none hover:bg-red-400/20 rounded-lg group"
      disabled={isDeleting}
    >
      <div className="flex w-full gap-x-2 items-center pl-4">
        <div className="bg-zinc-600 p-2 rounded-lg h-[24px] w-[24px] flex items-center justify-center">
          <Trash
            weight="bold"
            size={14}
            className="shrink-0 text-slate-100 group-hover:text-red-400"
          />
        </div>
        <p className="text-white text-left text-sm group-hover:text-red-400">
          {t("sidebar.thread.delete")}
        </p>
      </div>
    </button>
  );
}
