const TRANSLATIONS = {
  common: {
    "workspaces-name": "Nazwa przestrzeni roboczych",
    error: "błąd",
    success: "sukces",
    user: "Użytkownik",
    selection: "Wybór modelu",
    saving: "Zapisywanie...",
    save: "Zapisz zmiany",
    previous: "Poprzednia strona",
    next: "Następna strona",
  },

  // TextGenWebUI Component
  textgenwebui: {
    "base-url": "Podstawowy URL",
    "base-url-placeholder": "http://127.0.0.1:5000/v1",
    "token-window": "Kontekst okna tokenów",
    "token-window-placeholder": "Limit okna kontekstu (np. 4096)",
    "api-key": "Klucz API (opcjonalny)",
    "api-key-placeholder": "Klucz API TextGen Web UI",
    "max-tokens": "Maks. tokenów",
    "max-tokens-placeholder": "Maksymalna liczba tokenów na żądanie (np. 1024)",
  },

  // LMStudio Component
  lmstudio: {
    "max-tokens": "Maks. tokenów",
    "max-tokens-desc": "Maksymalna liczba tokenów dla kontekstu i odpowiedzi.",
    "show-advanced": "Pokaż ręczne wprowadzanie endpointu",
    "hide-advanced": "Ukryj ręczne wprowadzanie endpointu",
    "base-url": "Adres URL LM Studio",
    "base-url-placeholder": "http://localhost:1234/v1",
    "base-url-desc": "Wprowadź URL, pod którym działa LM Studio.",
    "auto-detect": "Auto-wykrywanie",
    model: "Model LM Studio",
    "model-loading": "--ładowanie dostępnych modeli--",
    "model-url-first": "Najpierw wprowadź URL LM Studio",
    "model-desc":
      "Wybierz model LM Studio, którego chcesz używać. Modele zostaną załadowane po wprowadzeniu prawidłowego adresu URL LM Studio.",
    "model-choose":
      "Wybierz model LM Studio, którego chcesz używać w swoich rozmowach.",
    "model-loaded": "Twoje załadowane modele",
    "embedding-required":
      "Użycie LMStudio jako LLM wymaga ustawienia usługi embeddingu.",
    "manage-embedding": "Zarządzaj embeddingiem",
  },

  // LocalAI Component
  localai: {
    "token-window": "Kontekst okna tokenów",
    "token-window-placeholder": "4096",
    "api-key": "Klucz API Local AI",
    "api-key-optional": "opcjonalne",
    "api-key-placeholder": "sk-mysecretkey",
    "show-advanced": "Pokaż zaawansowane ustawienia",
    "hide-advanced": "Ukryj zaawansowane ustawienia",
    "base-url": "Adres URL Local AI",
    "base-url-placeholder": "http://localhost:8080/v1",
    "auto-detect": "Auto-wykrywanie",
  },

  // Confluence Connector Component
  confluence: {
    "space-key": "Klucz przestrzeni Confluence",
    "space-key-desc":
      "To jest klucz przestrzeni w twojej instancji Confluence, który będzie używany. Zwykle zaczyna się od ~",
    "space-key-placeholder": "np: ~7120208c08555d52224113949698b933a3bb56",
    "url-placeholder":
      "np: https://example.atlassian.net, http://localhost:8211 itd...",
    "token-tooltip": "Możesz utworzyć token API",
    "token-tooltip-here": "tutaj",
  },

  // Popup Confirmation
  deleteWorkspaceConfirmation:
    "Czy na pewno chcesz usunąć {{name}}?\nPo wykonaniu tej operacji workspace nie będzie już dostępny.\n\nTej czynności nie można cofnąć.",
  deleteConfirmation:
    "Czy na pewno chcesz usunąć ${user.username}?\nPo wykonaniu tej operacji zostanie on wylogowany i nie będzie mógł ponownie uzyskać dostępu.\n\nTej czynności nie można cofnąć.",
  suspendConfirmation:
    "Czy na pewno chcesz zawiesić użytkownika {{username}}?\nPo wykonaniu tej operacji zostanie on wylogowany i nie będzie mógł się ponownie zalogować, dopóki administrator nie cofnie zawieszenia.",
  flushVectorCachesWorkspaceConfirmation:
    "Czy na pewno chcesz wyczyścić pamięć podręczną wektorów dla tego workspace?",

  // Setting Sidebar menu items.
  settings: {
    title: "Ustawienia Instancji",
    system: "Ustawienia Ogólne",
    invites: "Zaproszenia",
    users: "Użytkownicy",
    workspaces: "Przestrzenie robocze",
    "workspace-chats": "Czaty w workspace",
    customization: "Dostosowywanie",
    "api-keys": "API Deweloperskie",
    llm: "LLM",
    transcription: "Transkrypcja",
    embedder: "Embedder",
    "text-splitting": "Dzielenie i grupowanie tekstu",
    "vector-database": "Baza danych wektorów",
    embeds: "Wbudowany Chat",
    "embed-chats": "Historia wbudowanego chatu",
    security: "Bezpieczeństwo",
    "event-logs": "Logi zdarzeń",
    privacy: "Prywatność i dane",
    "ai-providers": "Dostawcy AI",
    "agent-skills": "Umiejętności Agenta",
    admin: "Admin",
    tools: "Narzędzia",
    audio: "Preferencje audio",
    "link-settings": "Ustawienia",
    "default-settings": "Ustawienia domyślne",
    "browser-extension": "Rozszerzenie przeglądarki",
    "prompt-upgrade-llm": "Prompt Upgrade LLM",
    "voice-speech": "Głos i Mowa",
    "pdr-settings": "Ustawienia PDR",
  },

  // Qura buttons
  qura: {
    "copy-to-cora": "Qura source check",
    "qura-status": "Przycisk Qura jest",
    "copy-option": "Skopiuj opcję",
    "option-quest": "Pytanie",
    "option-resp": "Odpowiedź",
    "role-description":
      "Dodaj przycisk Qura, aby wywołać odpowiedzi w Qura.law",
  },

  // Page Definitions
  login: {
    "multi-user": {
      welcome: "Witamy w",
      "placeholder-username": "Adres e-mail",
      "placeholder-password": "Hasło",
      login: "Zaloguj",
      validating: "Sprawdzanie...",
      "forgot-pass": "Zapomniałeś hasła",
      reset: "Zresetuj",
    },
    "sign-in": {
      start: "Zaloguj się na swoje konto",
      end: "konto.",
    },
    button: "zaloguj",
    password: {
      forgot: "ZAPOMNIAŁEŚ HASŁA?",
      contact: "Skontaktuj się z administratorem systemu.",
    },
    publicMode: "Tryb publiczny",
  },

  binary_llm_selection: {
    "secondary-llm-toggle": "Wybór LLM binarny",
    "secondary-llm-toggle-description":
      "Włącz, aby zezwolić administratorom na wybór między dwoma modelami LLM w module opracowywania dokumentów.",
    "secondary-llm-toggle-status": "Status: ",
    "secondary-llm-user-level": "Poziom użytkownika dla drugiego LLM",
    "secondary-llm-user-level-description":
      "Włącz, aby WSZYSCY użytkownicy mogli wybierać między dwoma modelami LLM w workspace do opracowywania dokumentów.",
  },

  "new-workspace": {
    title: "Nowy Workspace",
    placeholder: "Mój Workspace",
    "legal-areas": "Obszary prawne",
    create: {
      title: "Utwórz nowy workspace",
      description:
        "Po utworzeniu tego workspace tylko administratorzy będą go widzieli. Możesz dodać użytkowników po utworzeniu.",
      error: "Błąd: ",
      cancel: "Anuluj",
      "create-workspace": "Utwórz workspace",
    },
  },

  "workspace-chats": {
    welcome: "Witamy w nowym workspace.",
    "desc-start": "Aby rozpocząć, możesz",
    "desc-mid": "załadować dokument",
    "desc-or": "lub",
    start: "Aby zacząć",
    "desc-end": "wyślij wiadomość.",
    prompt: {
      send: "Wyślij",
      "send-message": "Wyślij wiadomość",
      placeholder: "Zadaj pytanie prawne",
      slash: "Wyświetl wszystkie dostępne komendy slash do czatu.",
      "change-size": "Zmień rozmiar tekstu",
      reset: "Zresetuj czat",
      clear: "Wyczyść historię czatu i zacznij nowy czat",
      "new-preset": "Dodaj nowy preset",
      command: "Komenda",
      description: "Opis",
      save: "zapisz",
      small: "Mały",
      normal: "Normalny",
      large: "Duży",
      attach: "Dołącz plik do tego czatu",
      upgrade: "Ulepsz swój prompt",
      upgrading: "Ulepszanie promptu",
      "original-prompt": "Oryginalny prompt:",
      "upgraded-prompt": "Ulepszony prompt:",
      "edit-prompt": "Możesz edytować nowy prompt przed jego wysłaniem.",
      "speak-prompt": "Powiedz swój prompt",
      "view-agents":
        "Zobacz wszystkich dostępnych agentów, których możesz użyć",
      "ability-tag": "Zdolność",
    },
  },

  contextual: {
    checkbox: {
      label: "Kontekstowy embedding",
      hint: "Włącz kontekstowy embedding, aby wzbogacić proces embeddingu o dodatkowe parametry",
    },
    systemPrompt: {
      label: "Prompt systemowy",
      placeholder: "Wpisz wartość...",
      description:
        "Przykład: Podaj krótki, zwięzły kontekst, aby osadzić ten fragment w całym dokumencie, co ma ułatwić wyszukiwanie i odzyskiwanie fragmentu. Odpowiedz wyłącznie zwięzłym kontekstem, bez dodatków.",
    },
    userPrompt: {
      label: "Prompt użytkownika",
      placeholder: "Wpisz wartość...",
      description:
        "Przykład: <document>\n{file}\n</document>\nOto fragment, który chcemy umieścić w całym dokumencie\n<chunk>\n{chunk}\n</chunk>",
    },
  },

  header: {
    account: "Konto",
    login: "Logowanie",
    "sign-out": "Wyloguj",
  },

  workspace: {
    title: "Przestrzenie robocze instancji",
    description:
      "Oto wszystkie workspace dostępne w tej instancji. Usunięcie workspace spowoduje usunięcie wszystkich powiązanych czatów i ustawień.",
    "new-workspace": "Nowy Workspace",
    name: "Nazwa",
    link: "Link",
    users: "Użytkownicy",
    type: "Typ",
    "created-on": "Utworzony",
    save: "Zapisz zmianę",
    cancel: "Anuluj",
    deleted: {
      title: "Workspace nie znaleziono!",
      description:
        "Wygląda na to, że workspace o tej nazwie nie jest dostępny.",
      homepage: "Wróć do strony głównej",
    },
    "no-workspace": {
      title: "Brak dostępnych Workspace’ów",
      description: "Nie masz dostępu do żadnych workspace’ów.",
      "contact-admin": "Skontaktuj się z administratorem, aby uzyskać dostęp.",
      "learn-more": "Dowiedz się więcej o workspace’ach",
    },
  },

  // Workspace Settings menu items
  "workspaces-settings": {
    general: "Ustawienia Ogólne",
    chat: "Ustawienia Czatów",
    vector: "Baza danych wektorów",
    members: "Członkowie",
    agent: "Konfiguracja Agenta",
    "general-settings": {
      "workspace-name": "Nazwa Workspace",
      "desc-name": "Zmieni to tylko nazwę wyświetlaną workspace'a.",
      "assistant-profile": "Obraz profilowy asystenta",
      "assistant-image": "Dostosuj obraz profilowy asystenta w tym workspace.",
      "workspace-image": "Obraz Workspace",
      "remove-image": "Usuń obraz Workspace",
      delete: "Usuń Workspace",
      deleting: "Usuwanie Workspace...",
      update: "Zaktualizuj workspace",
      updating: "Aktualizowanie workspace...",
    },
    "chat-settings": {
      type: "Typ czatu",
      private: "Prywatny",
      standard: "Standardowy",
      "private-desc-start": "ręcznie przyznajesz dostęp",
      "private-desc-mid": "tylko",
      "private-desc-end": "konkretnym użytkownikom.",
      "standard-desc-start": "automatycznie przyznajesz dostęp",
      "standard-desc-mid": "wszystkim",
      "standard-desc-end": "nowym użytkownikom.",
    },
    users: {
      manage: "Zarządzaj użytkownikami",
      "workspace-member": "Brak członków workspace",
      username: "Adres e-mail",
      role: "Rola",
      date: "Data dodania",
      users: "Użytkownicy",
      search: "Wyszukaj użytkownika",
      "no-user": "Nie znaleziono użytkowników",
      select: "Zaznacz wszystko",
      unselect: "Odznacz wszystko",
      save: "Zapisz",
    },
    "linked-workspaces": {
      title: "Powiązane Workspace’y",
      description:
        "Jeśli workspace’y są powiązane, istotne dane prawne dla promptu będą automatycznie pobierane z każdego powiązanego obszaru prawnego. Należy pamiętać, że powiązane workspace’y mogą zwiększyć czas przetwarzania",
      "linked-workspace": "Brak powiązanych workspace’ów",
      manage: "Zarządzaj workspace’ami",
      name: "Nazwa",
      slug: "Slug",
      date: "Data dodania",
      workspaces: "Workspace’y",
      search: "Wyszukaj workspace",
      "no-workspace": "Nie znaleziono workspace",
      select: "Zaznacz wszystko",
      unselect: "Odznacz wszystko",
      save: "Zapisz",
    },
  },

  // General Appearance
  general: {
    vector: {
      title: "Liczba wektorów",
      description: "Całkowita liczba wektorów w twojej bazie danych wektorów.",
      vectors: "Liczba wektorów",
    },
    names: {
      description: "Zmieni to tylko nazwę wyświetlaną workspace'a.",
    },
    message: {
      title: "Sugestie wiadomości czatu",
      description:
        "Dostosuj komunikaty, które będą sugerowane użytkownikom w twoim workspace.",
      add: "Dodaj nową wiadomość",
      save: "Zapisz wiadomości",
      heading: "Wyjaśnij mi",
      body: "korzyści platformy",
      message: "Wiadomość",
      "new-heading": "Nagłówek",
    },
    pfp: {
      title: "Obraz profilowy asystenta",
      description: "Dostosuj obraz profilowy asystenta w tym workspace.",
      image: "Obraz Workspace",
      remove: "Usuń obraz Workspace",
    },
    delete: {
      delete: "Usuń Workspace",
      deleting: "Usuwanie Workspace...",
      "confirm-start": "Za chwilę usuniesz cały",
      "confirm-end":
        "workspace. To usunie wszystkie wektory w bazie.\n\nOryginalne pliki źródłowe pozostaną niezmienione. Tej czynności nie można cofnąć.",
    },
  },

  // Chat Settings
  chat: {
    llm: {
      title: "Dostawca LLM w workspace",
      description:
        "Konkretna usługa LLM i model, który będzie używany w tym workspace. Domyślnie używa systemowego dostawcy LLM i ustawień.",
      search: "Wyszukaj wszystkich dostawców LLM",
    },
    "speak-prompt": "Powiedz swój prompt",
    "view-agents": "Zobacz wszystkich dostępnych agentów",
    "ability-tag": "Zdolność",
    "change-text-size": "Zmień rozmiar tekstu",
    "aria-text-size": "Zmień rozmiar tekstu",
    model: {
      title: "Model czatu w workspace",
      description:
        "Konkretny model czatu, który będzie używany w tym workspace. Jeśli pozostawisz puste, zostanie użyta globalna preferencja LLM.",
      wait: "-- oczekiwanie na modele --",
    },
    mode: {
      title: "Tryb czatu",
      chat: {
        title: "Chat",
        "desc-start": "będzie udzielać odpowiedzi, używając wiedzy ogólnej LLM",
        and: "oraz",
        "desc-end": "kontekstu dokumentu, który zostanie znaleziony.",
      },
      query: {
        title: "Query",
        "desc-start": "udzieli odpowiedzi",
        only: "tylko",
        "desc-end": "jeśli znajdzie kontekst w dokumencie.",
      },
    },
    history: {
      title: "Historia czatu",
      "desc-start":
        "Liczba poprzednich wiadomości, które będą brane pod uwagę w krótkotrwałej pamięci modelu.",
      recommend: "Zalecane 20. ",
      "desc-end":
        "Wartości powyżej 45 mogą powodować błędy w zależności od rozmiaru wiadomości.",
    },
    prompt: {
      title: "Prompt",
      description:
        "Prompt, którego będzie używał workspace. Określ kontekst i instrukcje dla AI, aby generował odpowiednie odpowiedzi.",
    },
    refusal: {
      title: "Odpowiedź odmowy w trybie zapytań",
      "desc-start": "W trybie",
      query: "query",
      "desc-end":
        "możesz chcieć zwrócić spersonalizowaną odpowiedź odmowy, gdy nie znaleziono kontekstu w dokumencie.",
    },
    temperature: {
      title: "Temperatura LLM",
      "desc-start":
        "Ta opcja kontroluje, jak „kreatywne” będą odpowiedzi modelu LLM.",
      "desc-end":
        "Im wyższa wartość, tym bardziej kreatywne odpowiedzi. W niektórych modelach zbyt wysoka wartość może prowadzić do niespójnych wyników.",
      hint: "Większość modeli LLM ma określony dopuszczalny zakres wartości. Skonsultuj się z dokumentacją swojego dostawcy.",
    },
    "dynamic-pdr": {
      title: "Dynamiczne PDR dla Przestrzeni Roboczej",
      description:
        "Włącz lub wyłącz Dynamiczne PDR dla tej przestrzeni roboczej.",
      "global-enabled":
        "Dynamiczne PDR jest globalnie włączone i nie może być wyłączone dla poszczególnych przestrzeni roboczych.",
    },
  },

  // Vector Database
  "vector-workspace": {
    identifier: "Identyfikator bazy wektorowej",
    snippets: {
      title: "Maksymalna liczba fragmentów kontekstu",
      description:
        "Ta opcja kontroluje, ile fragmentów kontekstu zostanie wysłanych do LLM przy jednym czacie lub zapytaniu.",
      recommend: "Zalecane: 4",
    },
    doc: {
      title: "Próg podobieństwa dokumentów",
      description:
        "Minimalny wynik podobieństwa, który musi zostać osiągnięty, aby źródło zostało uznane za powiązane z czatem. Im wyższa wartość, tym bardziej podobne musi być źródło.",
      zero: "Brak ograniczeń",
      low: "Niski (wynik podobieństwa ≥ 0,25)",
      medium: "Średni (wynik podobieństwa ≥ 0,50)",
      high: "Wysoki (wynik podobieństwa ≥ 0,75)",
    },
    reset: {
      reset: "Reset bazy wektorowej",
      resetting: "Czyszczenie wektorów...",
      confirm:
        "Za chwilę zresetujesz bazę wektorową tego workspace. Usunie to wszystkie obecnie osadzone wektory.\n\nOryginalne pliki źródłowe pozostaną nietknięte. Tej czynności nie można cofnąć.",
      error: "Nie udało się zresetować bazy wektorowej workspace!",
      success: "Baza wektorowa workspace została zresetowana!",
    },
  },

  // Agent Configuration
  agent: {
    "performance-warning":
      "Wydajność modeli LLM, które nie wspierają wywoływania narzędzi, zależy w dużym stopniu od ich możliwości i trafności. Niektóre funkcje mogą być ograniczone lub nie działać poprawnie.",
    provider: {
      title: "Dostawca LLM agenta w workspace",
      description:
        "Konkretna usługa LLM i model, który będzie używany dla agenta @agent w tym workspace.",
    },
    mode: {
      chat: {
        title: "Model czatu agenta w workspace",
        description:
          "Konkretny model czatu, który będzie używany przez agenta @agent w tym workspace.",
      },
      title: "Model agenta w workspace",
      description:
        "Konkretny model LLM, który będzie używany przez agenta @agent w tym workspace.",
      wait: "-- oczekiwanie na modele --",
    },

    skill: {
      title: "Domyślne umiejętności agenta",
      description:
        "Wzbogać domyślne zdolności agenta o te wbudowane umiejętności. To ustawienie dotyczy wszystkich workspace’ów.",
      rag: {
        title: "RAG i pamięć długoterminowa",
        description:
          "Pozwól agentowi wykorzystać lokalne dokumenty do odpowiedzi na zapytania lub „zapamiętywać” treści do długoterminowego wyszukiwania.",
      },
      configure: {
        title: "Konfiguruj Umiejętności Agenta",
        description:
          "Dostosuj i ulepsz domyślne możliwości agenta, włączając lub wyłączając konkretne umiejętności. Te ustawienia mają zastosowanie w całym systemie.",
      },
      view: {
        title: "Przeglądaj i podsumowuj dokumenty",
        description:
          "Pozwól agentowi wylistować i podsumować zawartość plików w workspace.",
      },
      scrape: {
        title: "Skrapowanie stron internetowych",
        description:
          "Pozwól agentowi odwiedzać i pobierać zawartość stron internetowych.",
      },
      generate: {
        title: "Generowanie wykresów",
        description:
          "Umożliwia agentowi generowanie różnego rodzaju wykresów na podstawie dostarczonych danych lub informacji w czacie.",
      },
      save: {
        title: "Generowanie i zapisywanie plików w przeglądarce",
        description:
          "Umożliwia agentowi generowanie i zapisywanie plików, które można pobrać w przeglądarce.",
      },
      web: {
        title: "Wyszukiwanie i przeglądanie sieci w czasie rzeczywistym",
        "desc-start":
          "Pozwól agentowi przeszukiwać sieć, aby odpowiadać na pytania, łącząc się z dostawcą SERP (wyszukiwarki).",
        "desc-end":
          "Wyszukiwanie sieciowe w sesjach agenta nie będzie działać, dopóki to ustawienie nie zostanie skonfigurowane.",
      },
    },
  },

  // Workspace Chats
  recorded: {
    title: "Czaty w workspace",
    description:
      "Oto wszystkie zarejestrowane czaty i wiadomości wysłane przez użytkowników, uporządkowane według daty utworzenia.",
    export: "Eksportuj",
    table: {
      id: "Id",
      by: "Wysłane przez",
      workspace: "Workspace",
      prompt: "Prompt",
      response: "Odpowiedź",
      at: "Wysłano o",
      invoice: "Ref. faktury",
      "completion-token": "Token zakończenia",
      "prompt-token": "Token promptu",
    },
    "clear-chats": "Wyczyść czaty",
    "confirm-clear-chats":
      "Czy na pewno chcesz wyczyścić wszystkie czaty?\n\nTej czynności nie można cofnąć.",
    "fine-tune-modal": "Zamów model Fine-Tune",
    "confirm-delete.chat":
      "Czy na pewno chcesz usunąć ten czat?\n\nTej czynności nie można cofnąć.",
    next: "Następna strona",
    previous: "Poprzednia strona",
    filter: "Filtruj po nazwie użytkownika",
  },

  // Appearance
  appearance: {
    title: "Wygląd",
    description: "Dostosuj ustawienia wyglądu swojej platformy.",
    logo: {
      title: "Dostosuj logo",
      description: "Prześlij własne logo dla jasnego motywu.",
      add: "Dodaj własne logo",
      recommended: "Zalecany rozmiar: 800 x 200",
      remove: "Usuń",
    },
    logoDark: {
      title: "Dostosuj logo dla trybu ciemnego",
      description: "Prześlij własne logo dla trybu ciemnego.",
    },
    message: {
      title: "Dostosuj Wiadomości",
      description: "Dostosuj automatyczne wiadomości wyświetlane użytkownikom.",
      new: "Nowa",
      system: "system",
      user: "użytkownik",
      message: "wiadomość",
      assistant: "Asystent Czatowy",
      "double-click": "Kliknij dwukrotnie, aby edytować...",
      save: "Zapisz wiadomości",
    },
    icons: {
      title: "Niestandardowe ikony w stopce",
      description:
        "Dostosuj ikony w stopce wyświetlane na dole paska bocznego.",
      icon: "Ikona",
      link: "Link",
    },
    display: {
      title: "Język wyświetlania",
      description: "Wybierz preferowany język.",
    },
    color: {
      title: "Niestandardowe kolory",
      "desc-start": "Dostosuj",
      "desc-mid": "*background-color, *primary-color",
      "desc-and": "oraz",
      "desc-end": "*text-color",
      red: "Czerwony",
      gray: "Szary",
      foynet: "Foynet",
      brown: "Brązowy",
      green: "Zielony",
      yellow: "Żółty",
      cyan: "Cyjan",
      magenta: "Magenta",
      orange: "TenderFlow",
      purple: "Fioletowy",
      navy: "Granatowy",
      black: "Czarny",
    },
    login: {
      title: "Niestandardowy tekst na ekranie logowania",
      description: "Dostosuj tekst akapitu wyświetlany na stronie logowania.",
      placeholder: "Proszę skontaktować się z administratorem systemu",
      website: {
        title: "Dostosuj adres strony aplikacji",
        description: "Dostosuj adres URL twojej aplikacji.",
        toggle: "Wyświetl link do strony internetowej",
      },
      validation: {
        invalidURL: "Wprowadź prawidłowy adres URL.",
      },
    },
    siteSettings: {
      title: "Niestandardowe ustawienia witryny",
      description:
        "Zmień zawartość karty przeglądarki w celu dostosowania i brandingu.",
      tabTitle: "Tytuł karty",
      tabDescription:
        "Ustaw niestandardowy tytuł karty, gdy aplikacja jest otwarta w przeglądarce.",
      tabIcon: "Ikona Favicon",
      fabIconUrl: "Zdefiniuj adres URL obrazu do użycia jako favicon",
      placeholder: "URL do twojego obrazu",
    },
    appName: {
      title: "Niestandardowa nazwa aplikacji",
      description:
        "Ustaw niestandardową nazwę aplikacji wyświetlaną na logowaniu.",
    },
    customTab: {
      title: "Dostosuj nazwę kart",
      tab1: "Karta 1",
      tab2: "Karta 2",
      tab3: "Karta 3",
    },
  },

  // API Keys
  api: {
    title: "Klucze API",
    description:
      "Klucze API umożliwiają programowy dostęp i zarządzanie tą instancją.",
    link: "Przeczytaj dokumentację API",
    generate: "Wygeneruj nowy klucz API",
    table: {
      key: "Klucz API",
      by: "Utworzone przez",
      created: "Utworzono",
    },
    new: {
      title: "Utwórz nowy klucz API",
      description:
        "Po utworzeniu klucza API można go użyć do programowego dostępu i konfiguracji tej instancji.",
      doc: "Przeczytaj dokumentację API",
      cancel: "Anuluj",
      "create-api": "Utwórz klucz API",
    },
  },

  llm: {
    title: "Preferencja LLM",
    description:
      "Są to dane uwierzytelniające i ustawienia dla preferowanego dostawcy LLM do czatu i embedowania. Ważne, aby te klucze były aktualne i prawidłowe, w przeciwnym razie system nie będzie działał poprawnie.",
    provider: "Dostawca LLM",
    "none-selected": "Nie wybrano",
    "select-llm": "Musisz wybrać LLM",
    "search-llm": "Wyszukaj dostawców LLM",
    "context-window-warning":
      "Ostrzeżenie: Nie udało się pobrać okna kontekstowego dla wybranego modelu.",
    "prompt-upgrade": {
      title: "Dostawca LLM do ulepszania promptu",
      description:
        "Konkretna usługa LLM i model używany do ulepszania promptu użytkownika. Domyślnie korzysta z ustawień systemowego LLM.",
      search: "Wyszukaj dostępnych dostawców LLM dla tej funkcji",
    },
  },

  "llm-provider": {
    openai: "Standardowa opcja do większości zastosowań niekomercyjnych.",
    azure: "Opcja dla przedsiębiorstw – OpenAI hostowane w Azure.",
    anthropic: "Przyjazny Asystent AI hostowany przez Anthropic.",
    gemini: "Największy i najbardziej zaawansowany model AI od Google",
    huggingface:
      "Dostęp do ponad 150 000 otwartych modeli LLM i społeczności AI.",
    ollama: "Uruchom modele LLM lokalnie na własnej maszynie.",
    lmstudio:
      "Odkrywaj, pobieraj i uruchamiaj tysiące nowoczesnych modeli LLM w kilku krokach.",
    localai: "Uruchom modele LLM lokalnie na własnej maszynie.",
    togetherai: "Uruchamiaj modele open source z Together AI.",
    mistral: "Uruchamiaj modele open source z Mistral AI.",
    perplexityai:
      "Uruchamiaj potężne i podłączone do internetu modele hostowane przez Perplexity AI.",
    openrouter: "Ujednolicony interfejs dla wielu modeli LLM.",
    groq: "Najszybsze inferencje LLM do zastosowań w czasie rzeczywistym.",
    koboldcpp: "Uruchamiaj lokalne LLM za pomocą koboldcpp.",
    oobabooga:
      "Uruchamiaj lokalne LLM używając Oobabooga Text Generation Web UI.",
    cohere: "Korzystaj z zaawansowanych modeli Command od Cohere.",
    lite: "Uruchamiaj proxy LiteLLM zgodne z OpenAI dla różnych LLM.",
    "generic-openai":
      "Połącz się z dowolną usługą kompatybilną z OpenAi przez niestandardową konfigurację",
    native:
      "Użyj pobranego modelu Llama do prowadzenia rozmów w tej instancji.",
  },

  // audio preference
  audio: {
    title: "Preferencje rozpoznawania mowy",
    provider: "Dostawca",
    "desc-speech":
      "Możesz wybrać, jakich dostawców rozpoznawania tekstu z mowy chcesz używać w swojej platformie. Domyślnie korzystamy z wbudowanych możliwości przeglądarki, ale możesz wybrać inne.",
    "title-text": "Preferencje syntezy mowy",
    "desc-text":
      "Tutaj możesz określić, z jakich dostawców syntezy mowy chcesz korzystać. Domyślnie używamy wbudowanych możliwości przeglądarki, ale możesz wybrać inne usługi.",
    "desc-config":
      "Brak dodatkowej konfiguracji wymaganej do natywnej syntezy mowy w przeglądarce.",
    "placeholder-stt": "Wyszukaj dostawców STT (mowa -> tekst)",
    "placeholder-tts": "Wyszukaj dostawców TTS (tekst -> mowa)",
    "native-stt":
      "Używa wbudowanej usługi rozpoznawania mowy w przeglądarce, jeśli jest obsługiwana.",
    "native-tts":
      "Używa wbudowanej usługi syntezy mowy w przeglądarce, jeśli jest obsługiwana.",
    openai: {
      "api-key": "Klucz API",
      "api-key-placeholder": "Klucz OpenAI",
      "voice-model": "Model głosu",
    },
    elevenlabs: "Użyj technologii i głosów text-to-speech z ElevenLabs.",
  },

  transcription: {
    title: "Preferencje modelu transkrypcji",
    description:
      "Są to dane uwierzytelniające i ustawienia dla wybranego dostawcy modeli transkrypcji. Muszą być poprawne, aby pliki audio/wideo były prawidłowo transkrybowane.",
    provider: "Dostawca transkrypcji",
    "warn-start":
      "Korzystanie z lokalnego modelu whisper na maszynach z ograniczoną ilością RAM lub CPU może spowolnić platformę podczas przetwarzania plików multimedialnych.",
    "warn-recommend":
      "Zalecamy co najmniej 2 GB RAM i przesyłanie plików <10Mb.",
    "warn-end":
      "Wbudowany model zostanie automatycznie pobrany przy pierwszym użyciu.",
    "search-audio": "Wyszukaj dostawców transkrypcji audio",
  },

  embedding: {
    title: "Preferencje Embedding",
    "desc-start":
      "Jeżeli używasz LLM, które nie oferuje wbudowanego embeddingu, możesz potrzebować osobno określić dane uwierzytelniające do embeddingu.",
    "desc-end":
      "Embedding to proces konwersji tekstu na wektory. Te poświadczenia są niezbędne, aby pliki i prompty mogły być przetwarzane przez platformę.",
    provider: {
      title: "Dostawca Embeddingu",
      description:
        "Nie jest wymagana konfiguracja, gdy używasz wbudowanego systemu embeddingu platformy.",
      "search-embed": "Wyszukaj wszystkich dostawców embeddingu",
    },
    workspace: {
      title: "Preferencja embeddingu w workspace",
      description:
        "Konkretna usługa embeddingu i model, który będzie używany w tym workspace. Domyślnie używa systemowych ustawień i dostawcy embeddingu.",
      "multi-model":
        "Obsługa wielu modeli nie jest jeszcze dostępna dla tego dostawcy.",
      "workspace-use": "Ten workspace używa",
      "model-set": "ustawionego modelu systemowego.",
      embedding: "Model embeddingu w workspace",
      model:
        "Konkretny model embeddingu używany w tym workspace. Jeżeli puste, używa globalnej preferencji embeddingu.",
      wait: "-- oczekiwanie na modele --",
      setup: "Konfiguruj",
      use: "Aby użyć",
      "need-setup":
        "jako embeddera w tym workspace, najpierw musisz go skonfigurować.",
      cancel: "Anuluj",
      save: "zapisz",
      settings: "ustawienia",
      search: "Wyszukaj wszystkich dostawców embeddingu",
      "need-llm": "jako LLM w tym workspace musisz go wcześniej skonfigurować.",
    },
  },

  text: {
    title: "Preferencje dzielenia i grupowania tekstu",
    "desc-start":
      "Czasami możesz chcieć zmienić domyślną metodę dzielenia i grupowania nowych dokumentów przed umieszczeniem ich w bazie wektorowej.",
    "desc-end":
      "Powinieneś zmodyfikować to ustawienie tylko, jeśli rozumiesz, jak działa dzielenie tekstu i jakie są jego skutki.",
    "warn-start": "Zmiany tutaj będą miały zastosowanie tylko do",
    "warn-center": "nowo osadzonych dokumentów",
    "warn-end": ", nie wpłyną na istniejące dokumenty.",
    size: {
      title: "Rozmiar fragmentu tekstu",
      description:
        "Maksymalna liczba znaków, które mogą wystąpić w jednym wektorze.",
      recommend: "Maksymalna długość modelu embeddingu to",
    },

    overlap: {
      title: "Nakładanie fragmentów tekstu",
      description:
        "Maksymalna liczba znaków nakładających się podczas dzielenia między dwoma przyległymi fragmentami tekstu.",
    },
  },

  // Vector Database
  vector: {
    title: "Baza danych wektorów",
    description:
      "Są to dane uwierzytelniające i ustawienia dotyczące tego, jak instancja platformy będzie funkcjonować. Muszą być prawidłowe, w przeciwnym razie platforma nie będzie działać.",
    provider: {
      title: "Dostawca bazy wektorów",
      description: "LanceDB nie wymaga konfiguracji.",
      "search-db": "Wyszukaj wszystkich dostawców baz wektorów",
    },
  },

  // Embeddable Chat Widgets
  embeddable: {
    title: "Wbudowane Chat Widgety",
    description:
      "Wbudowane widgety czatu to publiczne interfejsy czatu powiązane z pojedynczym workspace. Pozwalają na tworzenie workspace’ów, które potem można opublikować.",
    create: "Utwórz embed",
    table: {
      workspace: "Workspace",
      chats: "Wysłane czaty",
      Active: "Aktywne domeny",
    },
  },

  "embed-chats": {
    title: "Embed Chats",
    export: "Eksportuj",
    description:
      "Oto wszystkie zarejestrowane czaty i wiadomości z dowolnego opublikowanego embedu.",
    table: {
      embed: "Embed",
      sender: "Nadawca",
      message: "Wiadomość",
      response: "Odpowiedź",
      at: "Wysłano o",
    },
  },

  multi: {
    title: "Tryb Multi-User",
    description:
      "Skonfiguruj instancję, aby umożliwić wielu użytkownikom dołączenie poprzez włączenie Trybu Multi-User.",
    enable: {
      "is-enable": "Tryb Multi-User jest włączony",
      enable: "Włącz Tryb Multi-User",
      description:
        "Domyślnie będziesz jedynym administratorem. Jako administrator musisz tworzyć konta dla nowych użytkowników i administratorów. Nie zgub hasła, bo tylko Administrator może resetować hasła.",
      username: "Adres e-mail administratora",
      password: "Hasło konta administratora",
    },
    password: {
      title: "Ochrona hasłem",
      description:
        "Zabezpiecz instancję hasłem. Jeśli je zapomnisz, nie ma metody odzyskiwania, więc upewnij się, że je zapisałeś.",
    },
    instance: {
      title: "Zabezpiecz instancję hasłem",
      description:
        "Domyślnie będziesz jedynym administratorem. Jako administrator musisz tworzyć konta dla nowych użytkowników i administratorów. Nie zgub hasła, bo tylko Administrator może je resetować.",
      password: "Hasło instancji",
    },
  },

  // Event Logs
  event: {
    title: "Logi zdarzeń",
    description:
      "Przeglądaj wszystkie działania i zdarzenia w tej instancji w celach monitorowania.",
    clear: "Wyczyść logi zdarzeń",
    table: {
      type: "Typ zdarzenia",
      user: "Użytkownik",
      occurred: "Wystąpiło o",
    },
  },

  // Privacy & Data-Handling
  privacy: {
    title: "Prywatność i przetwarzanie danych",
    description:
      "Konfiguracja dotycząca sposobu, w jaki zewnętrzni dostawcy i nasza platforma przetwarzają twoje dane.",
    llm: "Wybór LLM",
    embedding: "Preferencja Embedding",
    vector: "Baza danych wektorów",
    anonymous: "Anonimowa telemetria włączona",
    "desc-event":
      "Wszystkie zdarzenia nie rejestrują adresów IP i nie zawierają",
    "desc-id": "żadnych identyfikujących",
    "desc-cont":
      "treści, ustawień, czatów czy innych danych, poza czysto statystycznymi.",
    "desc-git": "Kod źródłowy dostępny na Github tutaj",
    "desc-end":
      "Jako projekt open-source szanujemy twoje prawo do prywatności i dążymy do zapewnienia prywatnego i bezpiecznego rozwiązania do integracji AI z dokumentami. Jeśli zdecydujesz się wyłączyć telemetrię, prosimy o podzielenie się uwagami, abyśmy mogli nadal ulepszać platformę.",
  },
  "default-chat": {
    welcome: "Witamy w IST Legal.",
    "choose-legal": "Wybierz obszar prawny po lewej stronie.",
  },
  invites: {
    title: "Zaproszenia",
    description:
      "Utwórz linki zaproszeń dla osób w twojej organizacji. Każde zaproszenie może zostać wykorzystane tylko przez jedną osobę.",
    link: "Utwórz link zaproszenia",
    accept: "Zaakceptowane przez",
    "created-by": "Utworzone przez",
    created: "Utworzono",
    new: {
      title: "Utwórz nowe zaproszenie",
      "desc-start":
        "Po utworzeniu będziesz mógł skopiować link do zaproszenia i wysłać go nowemu użytkownikowi, aby mógł utworzyć konto z",
      "desc-mid": "domyślną",
      "desc-end": "rolą i automatycznie dodać się do wybranych workspace’ów.",
      "auto-add": "Automatycznie dodaj zaproszonego do workspace’ów",
      "desc-add":
        "Opcjonalnie możesz automatycznie przypisać użytkownika do workspace’ów, wybierając je poniżej. Domyślnie użytkownik nie będzie miał dostępu do żadnych workspace’ów. Możesz przypisać workspace’y później.",
      cancel: "Anuluj",
      "create-invite": "Utwórz zaproszenie",
      error: "Błąd: ",
    },
    "link-copied": "Skopiowano link zaproszenia",
    "copy-link": "Kopiuj link zaproszenia",
    "delete-invite-confirmation":
      "Czy na pewno chcesz dezaktywować to zaproszenie?\nPo tej operacji nie będzie można go użyć.\n\nTej czynności nie można cofnąć.",
    status: {
      label: "Status",
      pending: "Oczekujące",
      disabled: "Wyłączone",
      claimed: "Zaakceptowane",
    },
  },

  "user-menu": {
    edit: "Edytuj konto",
    profile: "Zdjęcie profilowe",
    size: "800 x 800",
    "remove-profile": "Usuń zdjęcie profilowe",
    username: "Adres e-mail",
    "username-placeholder": "Wpisz adres e-mail",
    "new-password": "Nowe hasło",
    "new-password-placeholder": "Nowe hasło",
    cancel: "Anuluj",
    update: "Zaktualizuj konto",
    language: "Preferowany język",
    email: "Adres e-mail",
    "email-placeholder": "Wpisz adres e-mail",
  },

  sidebar: {
    thread: {
      "load-thread": "ładowanie wątków...",
      "starting-thread": "Rozpoczynanie wątku...",
      thread: "Nowy wątek",
      delete: "Usuń zaznaczone",
      rename: "Zmień nazwę",
      "delete-thread": "Usuń wątek",
      deleted: "usunięto",
      default: "domyślny",
      "empty-thread": "Wątek",
      "rename-message": "Jak chcesz nazwać ten wątek?",
      "delete-message":
        "Czy na pewno chcesz usunąć ten wątek? Wszystkie jego czaty zostaną usunięte. Nie można tego cofnąć.",
    },
  },

  thread_name_error:
    "Nazwa wątku musi zawierać co najmniej 3 znaki i może zawierać tylko litery, cyfry, spacje i myślniki.",

  embeder: {
    allm: "Użyj wbudowanego dostawcy embeddingu. Zero konfiguracji!",
    openai: "Standardowa opcja do większości zastosowań niekomercyjnych.",
    azure: "Opcja dla przedsiębiorstw – OpenAI hostowane w Azure.",
    localai: "Uruchamiaj modele embeddingu lokalnie na własnej maszynie.",
    ollama: "Uruchamiaj modele embeddingu lokalnie na własnej maszynie.",
    lmstudio:
      "Odkrywaj, pobieraj i uruchamiaj tysiące nowoczesnych modeli LLM w kilku krokach.",
    cohere: "Korzystaj z zaawansowanych modeli embeddingu od Cohere.",
    voyageai: "Korzystaj z zaawansowanych modeli embeddingu od Voyage AI.",
  },

  vectordb: {
    lancedb:
      "W 100% lokalna baza wektorów działająca w tej samej instancji, co platforma.",
    chroma:
      "Otwarta baza wektorów, którą możesz hostować samodzielnie lub w chmurze.",
    pinecone: "W 100% chmurowa baza wektorów dla zastosowań korporacyjnych.",
    zilliz:
      "Chmurowa baza wektorów z certyfikatem SOC 2, przeznaczona dla przedsiębiorstw.",
    qdrant: "Open source – lokalna lub rozproszona baza wektorów w chmurze.",
    weaviate: "Open source – lokalne i chmurowe rozwiązania wielomodalne.",
    milvus: "Open-source, wysoce skalowalna i wyjątkowo szybka.",
    astra: "Wyszukiwanie wektorowe do zastosowań w GenAI.",
  },

  system: {
    title: "Preferencje systemowe",
    "desc-start": "Są to ogólne ustawienia i konfiguracje twojej instancji.",
    user: "Użytkownicy mogą usuwać workspace’y",
    "desc-delete":
      "Zezwalaj użytkownikom niebędącym adminami na usuwanie workspace’ów, których są częścią. To usunie workspace dla wszystkich.",
    limit: {
      title: "Limit wiadomości",
      "desc-limit":
        "Ogranicz liczbę wiadomości, jakie użytkownik może wysłać w ciągu dnia.",
      "per-day": "Wiadomości dziennie",
      label: "Limit wiadomości jest obecnie ",
    },
    max_tokens: {
      title: "Maksymalna liczba aktywnych tokenów logowania na użytkownika",
      desc: "Ustaw maksymalną liczbę aktywnych tokenów autoryzacyjnych, jakie każdy użytkownik może posiadać jednocześnie. Po przekroczeniu tej liczby starsze tokeny zostaną automatycznie usunięte.",
      label: "Maksymalna liczba tokenów",
      help: "Wartość musi być większa niż 0",
    },
    state: {
      enabled: "Włączone",
      disabled: "Wyłączone",
    },
    "source-highlighting": {
      title: "Włączanie / Wyłączanie podświetlania źródeł",
      description: "Ukryj lub wyświetl użytkownikom podświetlanie źródeł.",
      label: "Cytowanie: ",
    },
    "usage-registration": {
      title: "Rejestracja użycia w celach rozliczeniowych",
      description:
        "Włącz lub wyłącz rejestrowanie fakturowania dla monitoringu systemu.",
      label: "Rejestrowanie do fakturowania jest ",
    },
    "forced-invoice-logging": {
      title: "Wymuszone logowanie faktur",
      description:
        "Włącz, aby wymagać podania numeru faktury przed skorzystaniem z platformy.",
      label: "Wymuszone logowanie faktur jest ",
    },
    save: "Zapisz zmiany",
  },
  "user-setting": {
    description:
      "Wszyscy użytkownicy z kontem w tej instancji. Usunięcie konta natychmiast odbiera dostęp do systemu.",
    "add-user": "Dodaj użytkownika",
    username: "Adres e-mail",
    role: "Rola",
    default: "Domyślny",
    manager: "Menedżer",
    admin: "Admin",
    "date-added": "Data dodania",
    "all-domains": "Wszystkie domeny",
    "other-users": "Inni użytkownicy (bez domeny)",
    edit: "Edytuj: ",
    "new-password": "Nowe hasło",
    "password-rule": "Hasło musi mieć co najmniej 8 znaków.",
    "update-user": "Zaktualizuj użytkownika",
    placeholder: "Wpisz adres e-mail",
    cancel: "Anuluj",
    "remove-user": "Usuń użytkownika",
    "remove-user-title": "Usuń użytkownika",
    "remove-user-confirmation": "Czy na pewno chcesz usunąć tego użytkownika?",
    error: "Błąd: ",
  },

  support: {
    title: "Adres e-mail do wsparcia",
    description:
      "Ustaw adres e-mail do wsparcia wyświetlany w menu użytkownika po zalogowaniu do instancji.",
    clear: "Wyczyść",
    save: "Zapisz",
  },
  "public-mode": {
    enable: "Włącz Tryb Użytkownika Publicznego",
    enabled: "Tryb Użytkownika Publicznego jest włączony",
  },
  button: {
    delete: "Usuń",
    edit: "Edytuj",
    suspend: "Zawieś",
    unsuspend: "Odwieś",
    save: "Zapisz",
    accept: "Akceptuj",
    decline: "Odrzuć",
    ok: "OK",
    "flush-vector-caches": "Wyczyść pamięć wektorów",
    cancel: "Anuluj",
    saving: "Zapisywanie",
  },

  "new-user": {
    title: "Dodaj użytkownika do instancji",
    username: "Adres e-mail",
    "username-ph": "Wpisz adres e-mail",
    password: "Hasło",
    "password-ph": "Początkowe hasło użytkownika",
    role: "Rola",
    default: "Domyślny",
    manager: "Menedżer",
    admin: "Administrator",
    description:
      "Po utworzeniu konta użytkownik musi się zalogować za pomocą początkowego hasła, aby uzyskać dostęp.",
    cancel: "Anuluj",
    "add-User": "Dodaj użytkownika",
    error: "Błąd: ",
    "invalid-email": "Wpisz prawidłowy adres e-mail.",
    permissions: {
      title: "Uprawnienia",
      default: [
        "Może tylko wysyłać czaty w workspace'ach, do których został dodany przez administratora lub menedżera.",
        "Nie może modyfikować żadnych ustawień systemowych.",
      ],
      manager: [
        "Może przeglądać, tworzyć i usuwać workspace’y oraz zmieniać ustawienia workspace.",
        "Może tworzyć, aktualizować i zapraszać nowych użytkowników do instancji.",
        "Nie może modyfikować dostawców LLM, bazy wektorów, embeddingu ani innych połączeń.",
      ],
      admin: [
        "Najwyższy poziom uprawnień.",
        "Może zobaczyć i zrobić wszystko w systemie.",
      ],
    },
  },

  "new-embed": {
    title: "Utwórz nowy embed dla workspace",
    error: "Błąd: ",
    "desc-start":
      "Po utworzeniu embedu otrzymasz link, który możesz umieścić na swojej stronie internetowej za pomocą prostej",
    script: "znacznika script",
    tag: ".",
    cancel: "Anuluj",
    "create-embed": "Utwórz embed",
    workspace: "Workspace",
    "desc-workspace":
      "To workspace, na którym będzie bazował chat. Wszystkie wartości domyślne dziedziczone są z workspace, o ile nie zostaną nadpisane.",
    "allowed-chat": "Dozwolony tryb chatu",
    "desc-query":
      "Ustaw, jak chatbot ma działać. Tryb Query oznacza, że odpowie tylko na pytania związane z dokumentem.",
    "desc-chat":
      "Chat pozwala na zadawanie nawet ogólnych pytań niezwiązanych z workspace.",
    "desc-response":
      "Chat: odpowiada na wszystkie pytania bez względu na kontekst",
    "query-response":
      "Query: odpowiada tylko na pytania związane z dokumentami we workspace",
    restrict: "Ogranicz żądania do domen",
    filter:
      "Ten filtr zablokuje żądania pochodzące spoza poniższej listy domen.",
    "use-embed":
      "Pozostawienie pustego pola oznacza, że każdy może użyć embedu na dowolnej stronie.",
    "max-chats": "Maks. czatów dziennie",
    "limit-chats":
      "Ogranicz liczbę czatów tego wbudowanego chatu w ciągu 24 godzin. Zero = brak limitu.",
    "chats-session": "Maks. czatów na sesję",
    "limit-chats-session":
      "Ogranicz liczbę czatów na sesję użytkownika w tym wbudowanym chacie w ciągu 24 godzin. Zero = brak limitu.",
    "enable-dynamic": "Włącz dynamiczne użycie modelu",
    "llm-override":
      "Pozwól na ustawienie preferowanego modelu LLM, aby zastąpić domyślny z workspace.",
    "llm-temp": "Włącz dynamiczną temperaturę LLM",
    "desc-temp":
      "Pozwól na ustawianie temperatury LLM, aby zastąpić domyślną wartość z workspace.",
    "prompt-override": "Włącz zastępowanie promptu",
    "desc-override":
      "Pozwól na ustawianie promptu systemowego, aby zastąpić domyślny z workspace.",
  },

  "show-toast": {
    "recovery-codes": "Kody odzyskiwania skopiowane do schowka",
    "scraping-website": "Skrapowanie strony - może to trochę potrwać.",
    "fetching-transcript": "Pobieranie transkrypcji dla filmu YouTube.",
    "updating-workspace": "Aktualizowanie workspace...",
    "flashing-started": "Rozpoczęto flashowanie...",
    "flashing-success": "Flashowanie zakończone sukcesem",
    "flashing-error": "Wystąpił błąd podczas flashowania: {{error}}",
    "workspace-updated": "Workspace zaktualizowany pomyślnie.",
    "link-uploaded": "Link przesłany pomyślnie",
    "password-reset": "Hasło zresetowane pomyślnie",
    "invalid-reset": "Nieprawidłowy token resetujący",
    "delete-option": "Nie udało się usunąć wątku!",
    "thread-deleted": "Wątek usunięto pomyślnie!",
    "chat-deleted": "Czat usunięty pomyślnie!",
    "failed-delete-chat": "Nie udało się usunąć czatu. Spróbuj ponownie.",
    "error-deleting-chat": "Wystąpił błąd podczas usuwania czatu.",
    "chat-memory-reset": "Pamięć czatu w workspace została zresetowana!",
    "picture-uploaded": "Zdjęcie profilowe przesłane.",
    "profile-updated": "Konto zaktualizowane.",
    "logs-cleared": "Logi zdarzeń wyczyszczone pomyślnie.",
    "preferences-updated": "Ustawienia systemowe zaktualizowane pomyślnie.",
    "user-created": "Użytkownik pomyślnie utworzony.",
    "user-creation-error": "Nie udało się utworzyć użytkownika: ",
    "user-deleted": "Użytkownik został usunięty z systemu.",
    "workspaces-saved": "Workspace’y zapisano pomyślnie!",
    "failed-workspaces":
      "Nie udało się zapisać workspace’ów. Spróbuj ponownie.",
    "api-deleted": "Klucz API trwale usunięty",
    "api-copied": "Klucz API skopiowany do schowka",
    "appname-updated":
      "Pomyślnie zaktualizowano niestandardową nazwę aplikacji.",
    "appname-update-error": "Nie udało się zaktualizować nazwy aplikacji: ",
    "language-updated": "Pomyślnie zaktualizowano język.",
    "palette-updated": "Pomyślnie zaktualizowano paletę kolorów.",
    "image-uploaded": "Obraz przesłany pomyślnie.",
    "logo-remove-error": "Błąd podczas usuwania logo: ",
    "logo-removed": "Logo zostało pomyślnie usunięte.",
    "logo-uploaded": "Logo przesłane pomyślnie.",
    "logo-upload-error": "Nie udało się przesłać logo: ",
    "updated-welcome": "Pomyślnie zaktualizowano wiadomości powitalne.",
    "update-welcome-error":
      "Nie udało się zaktualizować wiadomości powitalnych:",
    "updated-footer": "Pomyślnie zaktualizowano ikony w stopce.",
    "update-footer-error": "Nie udało się zaktualizować ikon w stopce: ",
    "updated-paragraph": "Pomyślnie zaktualizowano niestandardowy tekst.",
    "update-paragraph-error": "Nie udało się zaktualizować tekstu: ",
    "updated-supportemail": "Pomyślnie zaktualizowano adres e-mail wsparcia.",
    "update-supportemail-error":
      "Nie udało się zaktualizować adresu e-mail wsparcia: ",
    "stt-success": "Pomyślnie zapisano preferencje rozpoznawania mowy.",
    "tts-success": "Pomyślnie zapisano preferencje syntezy mowy.",
    "failed-chats-export": "Nie udało się wyeksportować czatów.",
    "chats-exported": "Czaty wyeksportowano pomyślnie jako {{name}}.",
    "cleared-chats": "Wyczyszczono wszystkie czaty.",
    "embed-deleted": "Embed usunięty z systemu.",
    "snippet-copied": "Fragment skopiowany do schowka!",
    "embed-updated": "Embed zaktualizowany pomyślnie.",
    "embedding-saved": "Pomyślnie zapisano preferencje embeddingu.",
    "chunking-settings": "Zapisano ustawienia strategii dzielenia tekstu.",
    "llm-saved": "Pomyślnie zapisano preferencje LLM.",
    "llm-saving-error": "Nie udało się zapisać ustawień LLM: ",
    "multiuser-enabled": "Tryb Multi-User włączony pomyślnie.",
    "publicuser-enabled": "Tryb Użytkownika Publicznego włączony pomyślnie.",
    "publicuser-disabled": "Tryb Użytkownika Publicznego wyłączony pomyślnie.",
    "page-refresh": "Strona odświeży się za kilka sekund.",
    "transcription-saved": "Pomyślnie zapisano preferencje transkrypcji.",
    "vector-saved": "Pomyślnie zapisano preferencje bazy wektorów.",
    "workspace-not-deleted": "Nie udało się usunąć workspace!",
    "maximum-messages": "Można wprowadzić maksymalnie 4 wiadomości.",
    "users-updated": "Użytkownicy zaktualizowani pomyślnie.",
    "vectordb-not-reset": "Nie udało się zresetować bazy wektorowej workspace!",
    "vectordb-reset": "Baza wektorowa workspace została zresetowana!",
    "meta-data-update": "Ustawienia witryny zaktualizowane!",
    "linked-workspaces-updated":
      "Powiązane workspace’y zaktualizowano pomyślnie.",
    "upgrade-answer-error": "Nie udało się ulepszyć odpowiedzi: ",
    "upgrade-text-error": "Nie udało się ulepszyć tekstu: ",
    "reset-tab-name-error": "Nie udało się przywrócić domyślnej nazwy karty.",
    "update-tab-name-error": "Nie udało się zaktualizować nazw kart: ",
    "updated-website": "Pomyślnie zaktualizowano ustawienia strony.",
    "update-website-error": "Nie udało się zaktualizować linku do strony: ",
    "reset-website-error":
      "Nie udało się przywrócić domyślnego linku do strony.",
    "palette-update-error": "Nie udało się zaktualizować palety kolorów: ",
    "citation-state-updated":
      "Ustawienie cytowania zaktualizowano pomyślnie. {{citationState}}",
    "citation-state-update-error":
      "Nie udało się zaktualizować ustawienia cytowania",
    "citation-update-error": "Błąd podczas wysyłania ustawienia cytowania",
    "message-limit-updated":
      "Ustawienia limitu wiadomości zaktualizowano pomyślnie.",
    "validate-response-error": "Weryfikacja nie powiodła się z ",
    "invoice-logging-state-updated":
      "Ustawienia logowania fakturowania zaktualizowane pomyślnie.",
    "invoice-logging-state-update-error":
      "Błąd przy aktualizowaniu stanu logowania faktur: ",
    "error-fetching-tab-names": "Błąd podczas pobierania nazw kart",
    "active-case": {
      "reference-updated": "Numer referencyjny sprawy zaktualizowano pomyślnie",
      "reference-cleared":
        "Aktualna referencja dokumentu wyczyszczona pomyślnie",
    },
  },

  "llm-selection-privacy": {
    openai: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twoje prompty i tekst dokumentów użyte do generowania odpowiedzi są widoczne dla OpenAI",
      ],
    },
    azure: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twój tekst i embedding nie są widoczne dla OpenAI ani Microsoftu",
      ],
    },
    anthropic: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twoje prompty i tekst dokumentów użyte do generowania odpowiedzi są widoczne dla Anthropic",
      ],
    },
    gemini: {
      description: [
        "Twoje czaty są de-identyfikowane i używane w treningu",
        "Twoje prompty i tekst dokumentów użyte do odpowiedzi są widoczne dla Google",
      ],
    },
    lmstudio: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze, na którym działa LMStudio",
      ],
    },
    localai: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze, na którym działa LocalAI",
      ],
    },
    ollama: {
      description: [
        "Twój model i czaty są dostępne tylko na maszynie, na której uruchomiono modele Ollama",
      ],
    },
    native: {
      description: ["Twój model i czaty są dostępne wyłącznie w tej instancji"],
    },
    togetherai: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twoje prompty i tekst dokumentów użyte do odpowiedzi są widoczne dla TogetherAI",
      ],
    },
    mistral: {
      description: [
        "Twoje prompty i tekst dokumentów użyte do odpowiedzi są widoczne dla Mistral",
      ],
    },
    huggingface: {
      description: [
        "Twoje prompty i tekst dokumentów użyte w odpowiedzi są wysyłane do twojego endpointu na HuggingFace",
      ],
    },
    perplexity: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twoje prompty i tekst dokumentów użyte do odpowiedzi są widoczne dla Perplexity AI",
      ],
    },
    openrouter: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twoje prompty i tekst dokumentów użyte do odpowiedzi są widoczne dla OpenRouter",
      ],
    },
    groq: {
      description: [
        "Twoje czaty nie będą używane do treningu",
        "Twoje prompty i tekst dokumentów użyte do odpowiedzi są widoczne dla Groq",
      ],
    },
    koboldcpp: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze, na którym uruchomiono KoboldCPP",
      ],
    },
    textgenwebui: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze z Oobabooga Text Generation Web UI",
      ],
    },
    "generic-openai": {
      description: [
        "Dane są udostępniane zgodnie z warunkami świadczenia usług od twojego niestandardowego endpointu.",
      ],
    },
    cohere: {
      description: [
        "Dane są udostępniane zgodnie z warunkami świadczenia usług cohere.com i przepisami prawnymi w twoim regionie.",
      ],
    },
    litellm: {
      description: [
        "Twój model i czaty są dostępne tylko na serwerze, na którym działa LiteLLM",
      ],
    },
  },

  "vector-db-privacy": {
    chroma: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane w twojej instancji Chroma",
        "Dostęp do twojej instancji zarządzasz samodzielnie",
      ],
    },
    pinecone: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane na serwerach Pinecone",
        "Dostęp do twoich danych jest zarządzany przez Pinecone",
      ],
    },
    qdrant: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane w twojej instancji Qdrant (lokalnej lub w chmurze)",
      ],
    },
    weaviate: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane w twojej instancji Weaviate (lokalnej lub w chmurze)",
      ],
    },
    milvus: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane w twojej instancji Milvus (lokalnej lub w chmurze)",
      ],
    },
    zilliz: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane w klastrze w chmurze Zilliz",
      ],
    },
    astra: {
      description: [
        "Twoje wektory i tekst dokumentów są przechowywane w bazie chmurowej AstraDB",
      ],
    },
    lancedb: {
      description: [
        "Twoje wektory i tekst dokumentów są prywatnie przechowywane w tej instancji platformy",
      ],
    },
  },

  "embedding-engine-privacy": {
    native: {
      description: [
        "Twój tekst dokumentów jest osadzany prywatnie w tej instancji platformy",
      ],
    },
    openai: {
      description: [
        "Twój tekst dokumentów jest wysyłany do serwerów OpenAI",
        "Twoje dokumenty nie są używane do treningu",
      ],
    },
    azure: {
      description: [
        "Twój tekst dokumentów jest wysyłany do usługi Microsoft Azure",
        "Twoje dokumenty nie są używane do treningu",
      ],
    },
    localai: {
      description: [
        "Twój tekst dokumentów jest osadzany prywatnie na serwerze z LocalAI",
      ],
    },
    ollama: {
      description: [
        "Twój tekst dokumentów jest osadzany prywatnie na serwerze uruchamiającym Ollama",
      ],
    },
    lmstudio: {
      description: [
        "Twój tekst dokumentów jest osadzany prywatnie na serwerze z LMStudio",
      ],
    },
    cohere: {
      description: [
        "Dane są udostępniane zgodnie z warunkami świadczenia usług cohere.com i przepisami prawnymi w twoim regionie.",
      ],
    },
    voyageai: {
      description: [
        "Dane wysyłane do serwerów Voyage AI są udostępniane zgodnie z warunkami voyageai.com.",
      ],
    },
  },

  "prompt-validate": {
    edit: "Edytuj",
    response: "Odpowiedź",
    prompt: "Prompt",
    regenerate: "Wygeneruj ponownie odpowiedź",
    good: "Dobra odpowiedź",
    bad: "Zła odpowiedź",
    copy: "Kopiuj",
    more: "Więcej akcji",
    fork: "Rozgałęź",
    delete: "Usuń",
    cancel: "Anuluj",
    save: "Zapisz i prześlij",
  },

  citations: {
    show: "Pokaż cytowania",
    hide: "Ukryj cytowania",
    chunk: "Fragment cytowany",
    pdr: "Dokument nadrzędny",
    "pdr-h": "Podświetlanie w dokumencie",
    referenced: "Wspomniany",
    times: "razy.",
    citation: "Cytowanie",
    match: "dopasowanie",
    download:
      "Ta przeglądarka nie obsługuje PDF. Pobierz plik PDF, aby go wyświetlić:",
    "download-btn": "Pobierz PDF",
  },

  "document-drafting": {
    title: "Opracowywanie dokumentów",
    description: "Kontroluj ustawienia dotyczące opracowywania dokumentów.",
    configuration: "Konfiguracja",
    "drafting-model": "Model do opracowywania",
    enabled: "Opracowywanie dokumentów jest włączone",
    disabled: "Opracowywanie dokumentów jest wyłączone",
    "enabled-toast": "Opracowywanie dokumentów włączone",
    "disabled-toast": "Opracowywanie dokumentów wyłączone",
    "desc-settings":
      "Administrator może zmieniać ustawienia opracowywania dokumentów dla wszystkich użytkowników.",
    "drafting-llm": "Preferencja LLM do opracowywania dokumentów",
    saving: "Zapisywanie...",
    save: "Zapisz zmiany",
    "chat-settings": "Ustawienia czatu",
    "drafting-chat-settings": "Ustawienia czatu do opracowywania dokumentów",
    "chat-settings-desc":
      "Kontroluj zachowanie funkcji czatu w module opracowywania dokumentów.",
    "drafting-prompt": "Systemowy prompt do opracowywania dokumentów",
    "drafting-prompt-desc":
      "Prompt systemowy używany w opracowywaniu dokumentów różni się od systemowego promptu do pytań prawnych. Definiuje kontekst i instrukcje, aby AI mogło generować trafne odpowiedzi.",
    linking: "Łączenie dokumentów",
    "legal-issues-prompt": "Prompt do zagadnień prawnych",
    "legal-issues-prompt-desc": "Wpisz prompt do zagadnień prawnych.",
    "memo-prompt": "Prompt do memos",
    "memo-prompt-desc": "Wpisz prompt do memo.",
    "desc-linkage":
      "Włącz dodawanie dodatkowego kontekstu prawnego poprzez wykonywanie wyszukiwań Wektorowych/PDR oprócz pobierania notatek",
    message: {
      title: "Sugerowane wiadomości do tworzenia dokumentów",
      description:
        "Dodaj sugerowane wiadomości, które użytkownicy mogą wybrać podczas tworzenia dokumentów.",
      heading: "Standardowy nagłówek wiadomości",
      body: "Standardowa treść wiadomości",
      "new-heading": "Nagłówek wiadomości",
      message: "Treść wiadomości",
      add: "Dodaj wiadomość",
      save: "Zapisz wiadomości",
    },
  },

  "dd-settings": {
    title: "Ustawienia Document Drafting",
    description:
      "Kontroluj limity tokenów i zachowanie funkcji opracowywania dokumentów",
    "vector-search": {
      title: "Wyszukiwanie wektorowe",
      description:
        "Użyj wyszukiwania wektorowego, aby znaleźć powiązane dokumenty",
    },
    "memo-generation": {
      title: "Generowanie memo",
      description:
        "Generuj prawne memo zagadnień w celu kontekstu wyszukiwania",
    },
    "base-generation": {
      title: "Podstawowa analiza prawna",
      description:
        "Generuj początkową analizę kwestii prawnych na podstawie promptów użytkownika",
    },
    "linked-workspace-impact": {
      title: "Wpływ powiązanych workspace’ów na tokeny",
      description:
        "Dostosuj dostępne tokeny w zależności od liczby powiązanych workspace’ów",
    },
    "vector-token-limit": {
      title: "Limit tokenów dla wyszukiwania wektorowego",
      description:
        "Maksymalna liczba tokenów na powiązany workspace dla wyszukiwania wektorowego",
    },
    "memo-token-limit": {
      title: "Limit tokenów dla memo",
      description:
        "Maksymalna liczba tokenów przy generowaniu memo zagadnień prawnych",
    },
    "base-token-limit": {
      title: "Limit tokenów dla bazowych treści",
      description:
        "Maksymalna liczba tokenów dla pobierania podstawowych informacji",
    },
    "toast-success": "Ustawienia zaktualizowano pomyślnie",
    "toast-fail": "Nie udało się zaktualizować ustawień",
  },

  modale: {
    document: {
      title: "Moje Dokumenty",
      document: "Dokumenty",
      search: "Wyszukaj dokument",
      folder: "Nowy folder",
      name: "Nazwa",
      empty: "Brak dokumentów",
      "move-workspace": "Przenieś do Workspace",
      "doc-processor": "Procesor dokumentów niedostępny",
      "processor-offline":
        "Nie możemy w tej chwili załadować twoich plików, ponieważ procesor dokumentów jest offline. Spróbuj ponownie później.",
      "drag-drop": "Kliknij, aby wgrać lub przeciągnij i upuść",
      "supported-files":
        "obsługuje pliki tekstowe, CSV, arkusze kalkulacyjne, pliki audio i wiele innych!",
      "submit-link": "lub podaj link",
      fetch: "Pobierz stronę",
      fetching: "Pobieranie...",
      "file-desc":
        "Te pliki zostaną załadowane do procesora dokumentów działającego w tej instancji. Nie są wysyłane ani udostępniane osobom trzecim.",
      cost: "*Jednorazowy koszt embeddingu",
      "save-embed": "Zapisz i embeduj",
      "failed-uploads": "Nie udało się przesłać następujących plików",
      "loading-message": "W przypadku dużych dokumentów może to trochę potrwać",
    },
    connectors: {
      title: "Data Connectors",
      search: "Wyszukaj data connectors",
      empty: "Nie znaleziono data connectors.",
    },
  },

  dataConnectors: {
    github: {
      name: "Repozytorium GitHub",
      description:
        "Zaimportuj całą publiczną lub prywatną repozytorię Github jednym kliknięciem.",
      url: "URL Repozytorium GitHub",
      "collect-url": "Adres URL repozytorium GitHub, które chcesz zebrać.",
      "access-token": "Token dostępu do GitHub",
      optional: "opcjonalne",
      "rate-limiting": "Token dostępu, aby uniknąć limitów zapytań.",
      "desc-picker":
        "Po zakończeniu wszystkie pliki będą dostępne do osadzenia w workspace w przeglądarce dokumentów.",
      branch: "Gałąź",
      "branch-desc": "Gałąź, z której chcesz pobrać pliki.",
      "branch-loading": "-- ładowanie dostępnych gałęzi --",
      "desc-start": "Bez wypełniania",
      "desc-token": "Token dostępu do GitHub",
      "desc-connector": "ten data connector będzie w stanie pobrać tylko",
      "desc-level": "pliki z najwyższego poziomu",
      "desc-end": "repozytorium z powodu limitów publicznego API GitHuba.",
      "personal-token":
        "Uzyskaj darmowy Personal Access Token, logując się na GitHub tutaj.",
      without: "Bez",
      "personal-token-access": "Personal Access Token",
      "desc-api":
        "API GitHuba może ograniczyć liczbę pobieranych plików z powodu limitów. Możesz",
      "temp-token": "utworzyć tymczasowy token dostępu",
      "avoid-issue": "by uniknąć tego problemu.",
      submit: "Prześlij",
      "collecting-files": "Zbieranie plików...",
    },
    "youtube-transcript": {
      name: "Transkrypcja YouTube",
      description: "Zaimportuj transkrypcję całego filmu YouTube z linku.",
      url: "URL filmu YouTube",
      "url-video": "Adres URL filmu YouTube, który chcesz transkrybować.",
      collect: "Pobierz transkrypcję",
      collecting: "Pobieranie transkrypcji...",
      "desc-end":
        "po zakończeniu transkrypcja będzie dostępna w przeglądarce dokumentów do osadzenia w workspace.",
    },
    "website-depth": {
      name: "Bulk Link Scraper",
      description:
        "Skrapuj stronę i jej podlinkowane strony do określonej głębokości.",
      url: "Adres URL strony",
      "url-scrape": "Adres URL strony, którą chcesz skrapować.",
      depth: "Głębokość",
      "child-links":
        "Liczba podlinkowanych stron, jakie worker powinien przejrzeć od adresu źródłowego.",
      "max-links": "Maks. linków",
      "links-scrape": "Maksymalna liczba linków do skrapowania.",
      scraping: "Skrapowanie strony...",
      submit: "Prześlij",
      "desc-scrap":
        "Po zakończeniu wszystkie podstrony będą dostępne do osadzenia w workspace w przeglądarce dokumentów.",
    },
    confluence: {
      name: "Confluence",
      description: "Zaimportuj całą stronę Confluence jednym kliknięciem.",
      url: "URL strony Confluence",
      "url-page": "Adres URL strony w przestrzeni Confluence.",
      username: "Nazwa użytkownika Confluence",
      "own-username": "Twoja nazwa użytkownika w Confluence.",
      token: "Token dostępu Confluence",
      "desc-start":
        "Musisz podać token dostępu do uwierzytelniania. Możesz go wygenerować",
      here: "tutaj",
      access: "Token dostępu do uwierzytelniania.",
      collecting: "Zbieranie stron...",
      submit: "Prześlij",
      "desc-end":
        "Po zakończeniu wszystkie strony będą dostępne do osadzenia w workspace.",
    },
  },

  module: {
    "legal-qa": "Pytania Prawne",
    "document-drafting": "Opracowywanie Dokumentów",
    "active-case": "Aktywna Sprawa",
  },

  "fine-tune": {
    title: "Masz wystarczająco danych do fine-tune!",
    link: "kliknij, aby dowiedzieć się więcej",
    dismiss: "ukryj",
  },
  mobile: {
    disclaimer:
      "UWAGA: Aby uzyskać najlepsze wrażenia i pełny dostęp do funkcji, zalecamy korzystanie z aplikacji na komputerze.",
  },
  onboarding: {
    welcome: "Witamy w",
    "get-started": "Rozpocznij",
    "llm-preference": {
      title: "Preferencje LLM",
      description:
        "ISTLLM może współpracować z wieloma dostawcami modeli LLM. Będzie to usługa obsługująca czat.",
      "LLM-search": "Wyszukaj dostawców LLM",
    },
    "user-setup": {
      title: "Konfiguracja Użytkownika",
      description: "Skonfiguruj ustawienia dla swojego użytkownika.",
      "sub-title": "Ilu osób będzie korzystać z twojej instancji?",
      "single-user": "Tylko ja",
      "multiple-user": "Mój zespół",
      "setup-password": "Czy chcesz skonfigurować hasło?",
      "password-requirment": "Hasło musi mieć co najmniej 8 znaków.",
      "save-password":
        "Ważne, aby zapisać to hasło, ponieważ nie istnieje metoda odzyskiwania.",
      "password-label": "Hasło do instancji",
      username: "Adres e-mail administratora",
      password: "Hasło konta administratora",
      "account-requirment":
        "Adres e-mail musi być prawidłowy i może zawierać tylko małe litery, cyfry, podkreślniki i myślniki bez spacji. Hasło musi mieć co najmniej 8 znaków.",
      "password-note":
        "Domyślnie będziesz jedynym administratorem. Po zakończeniu konfiguracji możesz tworzyć i zapraszać innych użytkowników lub administratorów. Nie zgub hasła, gdyż tylko admini mogą je resetować.",
    },
    "data-handling": {
      title: "Przetwarzanie Danych i Prywatność",
      description:
        "Zależy nam na przejrzystości i kontroli w zakresie twoich danych.",
      "llm-label": "Wybór LLM",
      "embedding-label": "Preferencja Embedding",
      "database-lablel": "Baza danych wektorów",
      "reconfigure-option":
        "Te ustawienia można zmienić w dowolnym momencie w ustawieniach.",
    },
    survey: {
      title: "Witamy w IST Legal LLM",
      description:
        "Pomóż nam lepiej dopasować IST Legal LLM do twoich potrzeb. (Opcjonalne)",
      email: "Jaki jest twój adres e-mail?",
      usage: "Do czego będziesz używać platformy?",
      work: "Do pracy",
      "personal-use": "Do użytku osobistego",
      other: "Inne",
      comment: "Uwagi dla zespołu?",
      optional: "(Opcjonalne)",
      feedback: "Dziękujemy za opinię!",
    },
    button: {
      yes: "Tak",
      no: "Nie",
      "skip-survey": "Pomiń ankietę",
    },
    placeholder: {
      "admin-password": "Twoje hasło administratora",
      "admin-username": "Twój e-mail administratora",
      "email-example": "twojemail@gmail.com",
      comment:
        "Jeśli masz jakieś pytania lub uwagi, możesz je tu zostawić. Możesz też napisać na adres: richard.sahlberg@foytech.se",
    },
  },
  "default-settings": {
    title: "Domyślne ustawienia dla Prawnych Pytań i Odpowiedzi",
    "default-desc":
      "Kontroluj domyślne zachowanie przestrzeni roboczych dla Prawnych Pytań i Odpowiedzi",
    prompt: "Systemowy prompt dla Prawnych Pytań i Odpowiedzi",
    "prompt-desc":
      "Domyślny prompt, który będzie używany w Prawnych Pytaniach i Odpowiedziach. Zdefiniuj kontekst i instrukcje dla AI do generowania odpowiedzi. Powinieneś dostarczyć starannie przygotowany prompt, aby AI mogło wygenerować trafną i dokładną odpowiedź.",
    "toast-success": "Zaktualizowano domyślny prompt systemowy",
    "toast-fail": "Nie udało się zaktualizować promptu systemowego",
    snippets: {
      title: "Domyślna maksymalna liczba fragmentów kontekstu",
      description:
        "Ta opcja kontroluje domyślną maksymalną liczbę fragmentów kontekstu wysyłanych do LLM przy pojedynczym czacie lub zapytaniu.",
      recommend: "Zalecane: 10",
    },
    "validation-prompt": {
      title: "Prompt weryfikacyjny",
      description:
        "Ta opcja kontroluje domyślny prompt weryfikujący wysyłany do LLM, aby sprawdzić poprawność udzielonej odpowiedzi.",
      placeholder:
        "Proszę zweryfikować następującą odpowiedź pod kątem dokładności odniesień prawnych i cytowań w stosunku do dostarczonego kontekstu. Wymień wszelkie nieścisłości lub błędne informacje.",
    },
    "rerank-limit": {
      title: "Maksymalny limit ponownego rankingu",
      description:
        "To ustawienie kontroluje maksymalną liczbę dokumentów, które będą brane pod uwagę przy ponownym rankingu. Wyższa liczba może zapewnić lepsze wyniki, ale wydłuży czas przetwarzania.",
      recommend: "Zalecane: 50",
    },
  },
  "confirm-message": {
    "delete-doc":
      "Czy na pewno chcesz usunąć te pliki i foldery?\nSpowoduje to usunięcie plików z systemu i usunięcie ich z wszelkich istniejących workspace'ów.\nTej operacji nie można cofnąć.",
  },
  performLegalTask: {
    title: "Wykonaj zadanie prawne",
    description:
      "Włącz lub wyłącz przycisk wykonania zadania prawnego w module Opracowywania Dokumentów.",
    successMessage: "Przycisk wykonania zadania prawnego został {{status}}",
    failureUpdateMessage:
      "Nie udało się zaktualizować ustawienia przycisku wykonania zadania prawnego.",
    errorSubmitting: "Błąd podczas przesyłania ustawień zadania prawnego.",
  },
  canvasChat: {
    title: "Canvas",
    "input-placeholder": "Zapytaj o informacje prawne",
  },
  statuses: {
    enabled: "włączony",
    disabled: "wyłączony",
  },
  "answer-upgrade": {
    title: "Ulepsz odpowiedź",
    "text-upgrade-prompt":
      "Prompt: Zmodyfikuj ten tekst, aby był bardziej {{prompt}}. Zwróć tylko zmodyfikowaną wersję tego dokładnego tekstu i nic więcej: {{selectedText}}",
    "category-step": {
      title: "Wybierz kategorię",
      description: "Wybierz kategorię najlepiej pasującą do twoich potrzeb",
      categories: {
        formality: {
          label: "Formalność",
          choices: {
            more_formal: "Uczyń to bardziej formalnym",
            less_formal: "Uczyń to mniej formalnym",
            more_professional: "Nadaj bardziej profesjonalny ton",
            more_casual: "Nadaj bardziej swobodny ton",
            more_polished: "Nadaj bardziej dopracowany styl",
            more_relaxed: "Zrelaksowany styl",
            academic_tone: "Użyj bardziej akademickiego tonu",
            conversational_tone: "Użyj bardziej konwersacyjnego tonu",
          },
        },
        complexity: {
          label: "Złożoność języka",
          choices: {
            simplify: "Uprość język",
            more_descriptive: "Dodaj bardziej opisowy język",
            complex_vocab: "Użyj bardziej złożonego słownictwa",
            simple_vocab: "Użyj prostszego słownictwa",
            technical: "Zwiększ użycie języka technicznego",
            layman: "Użyj bardziej potocznych określeń",
            add_jargon: "Dodaj żargon specyficzny dla branży",
            avoid_jargon: "Unikaj żargonu i używaj ogólnych terminów",
            add_rhetorical: "Dodaj więcej pytań retorycznych",
            less_rhetorical: "Zmniejsz liczbę pytań retorycznych",
          },
        },
        structure: {
          label: "Budowa zdań",
          choices: {
            shorter: "Skróć zdania",
            longer: "Wydłuż zdania",
            vary: "Urozmaicaj strukturę zdań",
            standardize: "Ustandaryzuj strukturę zdań",
            more_complex: "Użyj bardziej złożonych zdań",
            simpler: "Użyj prostszych zdań",
            active_voice: "Częstsze użycie strony czynnej",
            passive_voice: "Częstsze użycie strony biernej",
          },
        },
        figurative: {
          label: "Język metaforyczny",
          choices: {
            more_figurative: "Użyj więcej metafor i środków stylistycznych",
            less_figurative: "Ogranicz metafory i środki stylistyczne",
            metaphors: "Dodaj więcej metafor i porównań",
            literal: "Użyj bardziej dosłownego języka",
            more_idioms: "Włącz więcej idiomów",
            less_idioms: "Ogranicz idiomy",
            more_symbolism: "Zwiększ stosowanie symboliki",
            less_symbolism: "Zmniejsz stosowanie symboliki",
          },
        },
        conciseness: {
          label: "Zwięzłość",
          choices: {
            more_concise: "Uczyń to bardziej zwięzłym",
            more_wordy: "Dodaj więcej słów/rozbuduj treść",
            remove_redundant: "Usuń zbędne powtórzenia",
            add_details: "Dodaj więcej szczegółów",
            reduce_filler: "Ogranicz słowa-wypełniacze",
            add_elaboration: "Rozwiń wypowiedź",
          },
        },
        imagery: {
          label: "Obrazowość i detale zmysłowe",
          choices: {
            enhance_imagery: "Wzmocnij obrazowość",
            simplify_imagery: "Uprość obrazowość",
            vivid_descriptions: "Użyj bardziej żywych opisów",
            straightforward_descriptions: "Użyj bardziej bezpośrednich opisów",
            more_visual: "Dodaj więcej elementów wizualnych",
            less_visual: "Mniej nacisku na wizualne detale",
          },
        },
        paragraph: {
          label: "Struktura akapitów i tekstu",
          choices: {
            shorter_paragraphs: "Skróć akapity",
            longer_paragraphs: "Wydłuż akapity",
            break_sections: "Rozdziel tekst na mniejsze sekcje",
            combine_sections: "Połącz sekcje dla płynniejszego przepływu",
            more_lists: "Użyj więcej punktów i list",
            more_continuous: "Użyj bardziej ciągłego tekstu",
            vary_paragraphs: "Zwiększ różnorodność akapitów",
            consistent_length: "Utrzymuj spójną długość akapitów",
          },
        },
        other: {
          label: "Inne aspekty",
          choices: {
            replace_context:
              "Zastąp odwołania do CONTEXT właściwą nazwą źródła",
            add_numbering: "Dodaj numerację akapitów",
            remove_numbering: "Usuń numerację akapitów",
            extend_statutories: "Rozszerz tekst na temat przepisów prawnych",
            reduce_statutories: "Skróć treści dotyczące przepisów prawnych",
            extend_jurisprudence: "Rozszerz treści dotyczące orzecznictwa",
            reduce_jurisprudence: "Ogranicz treści dotyczące orzecznictwa",
          },
        },
      },
    },
    "prompt-step": {
      title: "Wybierz Prompt",
      description: "Wybierz, w jaki sposób chcesz ulepszyć odpowiedź",
    },
    actions: {
      next: "Dalej",
      back: "Wstecz",
      upgrade: "Ulepsz odpowiedź",
      cancel: "Anuluj",
    },
  },
  "pdr-settings": {
    title: "Ustawienia PDR",
    description:
      "Skonfiguruj ustawienia Parent Document Retrieval dla swoich przestrzeni roboczych.",
    "desc-end":
      "Te ustawienia wpływają na sposób przetwarzania i wykorzystywania dokumentów PDR w odpowiedziach czatu.",
    "global-override": {
      title: "Globalne Nadpisanie Dynamicznego PDR",
      description:
        "Po włączeniu wymusi to aktywację Dynamicznego PDR dla wszystkich przestrzeni roboczych, niezależnie od ich indywidualnych ustawień.",
    },
    "pdr-token-limit": "Limit tokenów PDR",
    "pdr-token-limit-desc":
      "Maksymalna liczba tokenów, która zostanie użyta dla algorytmu PDR.",
    "input-prompt-token-limit": "Limit tokenów dla promptu wejściowego",
    "input-prompt-token-limit-desc":
      "Maksymalna liczba tokenów, która zostanie użyta dla promptu wejściowego.",
    "response-token-limit": "Limit tokenów odpowiedzi",
    "response-token-limit-desc":
      "Maksymalna liczba tokenów używanych w odpowiedziach.",
    "toast-success": "Ustawienia PDR zaktualizowane",
    "toast-fail": "Nie udało się zaktualizować ustawień PDR",
    "adjacent-vector-limit": "Limit przyległych wektorów",
    "adjacent-vector-limit-desc":
      "Liczba sąsiadujących wektorów używanych przez algorytm PDR.",
    "keep-pdr-vectors": "Zachowaj wektory dokumentów PDR",
    "keep-pdr-vectors-desc":
      "Po włączeniu zarówno pełne dokumenty PDR, jak i ich fragmenty wektorowe zostaną uwzględnione w kontekście. Może to zapewnić bardziej kompleksowy kontekst, ale zużywa więcej tokenów.",
  },
  "validate-response": {
    title: "Wynik walidacji",
    "toast-fail": "Nie udało się zweryfikować odpowiedzi",
    validating: "Weryfikowanie odpowiedzi",
    button: "Zatwierdź odpowiedź",
    "adjust-prefix":
      "Wprowadź wszystkie wskazane poprawki do odpowiedzi na podstawie tej informacji zwrotnej: ",
    "adjust-button": "Zastosuj sugerowane zmiany",
  },

  "workspace-names": {
    "Administrative Law": "Prawo Administracyjne",
    "Business Law": "Prawo Gospodarcze",
    "Civil Law": "Prawo Cywilne",
    "Criminal Law": "Prawo Karne",
    "Diplomatic Law": "Prawo Dyplomatyczne",
    "Fundamental Law": "Prawo Konstytucyjne",
    "Human Rights Law": "Prawo Praw Człowieka",
    "Judicial Laws": "Przepisy Sądowe",
    "Security Laws": "Prawo Bezpieczeństwa",
    "Taxation Laws": "Prawo Podatkowe",
  },

  "validate-answer": {
    setting: "LLM Weryfikacyjny",
    title: "Preferencja LLM do Weryfikacji",
    description:
      "Są to dane uwierzytelniające i ustawienia dla wybranego LLM, który ma weryfikować odpowiedzi. Ważne, aby klucze były poprawne, w przeciwnym razie system nie będzie działał prawidłowo.",
    "toast-success": "Ustawienia LLM weryfikacyjnego zaktualizowane",
    "toast-fail": "Nie udało się zaktualizować ustawień LLM weryfikacyjnego",
    saving: "Zapisywanie...",
    "save-changes": "Zapisz zmiany",
  },

  "active-case": {
    title: "Wprowadź numer referencyjny sprawy",
    placeholder: "Przykład 1000-01",
  },
  errors: {
    "fetch-models": "Nie udało się pobrać modeli niestandardowych",
    "fetch-models-error": "Błąd podczas pobierania modeli",
    "upgrade-error": "Błąd podczas ulepszania",
  },
  loading: {
    models: "-- ładowanie dostępnych modeli --",
    "waiting-url": "-- oczekiwanie na URL --",
    "waiting-api-key": "-- oczekiwanie na klucz API --",
    "waiting-models": "-- oczekiwanie na modele --",
  },
  model: {
    selection: "Wybór modelu czatu",
    "embedding-selection": "Wybór modelu embeddingu",
    "enter-api-key":
      "Wprowadź prawidłowy klucz API, aby zobaczyć dostępne modele dla twojego konta.",
    "enter-url": "Najpierw wprowadź URL",
    "your-models": "Twoje załadowane modele",
    "available-models": "Dostępne modele",
  },
  charts: {
    downloading: "Pobieranie obrazu...",
    download: "Pobierz obraz wykresu",
  },
  litellm: {
    "model-tooltip":
      "Upewnij się, że wybrano odpowiedni model embeddingu. Modele czatu nie są modelami embeddingu. Zobacz",
    "model-tooltip-link": "tę stronę",
    "model-tooltip-more": "po więcej informacji.",
  },
  generic: {
    "chat-model": "Nazwa modelu czatu",
    "chat-model-placeholder": "Identyfikator modelu do zapytań czatu",
    "token-window": "Kontekst okna tokenów",
    "token-window-placeholder": "Limit okna kontekstu (np. 4096)",
    "max-tokens": "Maks. tokenów",
    "max-tokens-placeholder": "Maksymalna liczba tokenów na żądanie (np. 1024)",
    "embedding-deployment": "Nazwa wdrożenia embeddingu",
    "embedding-deployment-placeholder":
      "Nazwa wdrożenia modelu embeddingu Azure OpenAI",
  },
  modals: {
    warning: {
      title: "Ostrzeżenie",
      proceed: "Czy na pewno chcesz kontynuować?",
      cancel: "Anuluj",
      confirm: "Potwierdź",
      "got-it": "OK, rozumiem",
    },
  },
  documents: {
    "pin-title": "Na czym polega przypinanie dokumentu?",
    "pin-desc-1":
      "Kiedy przypinasz dokument, platforma wstrzykuje całą jego zawartość bezpośrednio do okna promptu, aby twój LLM miał pełen kontekst.",
    "pin-desc-2":
      "To rozwiązanie najlepiej się sprawdza z modelami posiadającymi duży kontekst lub przy niewielkich plikach, które są kluczowe dla wiedzy modelu.",
    "pin-desc-3":
      "Jeśli nie otrzymujesz oczekiwanych odpowiedzi w domyślny sposób, przypinanie jest dobrym rozwiązaniem, by uzyskać wyższej jakości odpowiedzi jednym kliknięciem.",
    "watch-title": "Co daje obserwowanie dokumentu?",
    "watch-desc-1":
      "Jeśli obserwujesz dokument, platforma będzie automatycznie synchronizować jego treść z oryginalnego źródła w regularnych odstępach czasu. Spowoduje to aktualizację zawartości we wszystkich workspace’ach, w których ten plik jest używany.",
    "watch-desc-2":
      "Funkcja ta dotyczy obecnie zawartości dostępnej w sieci i nie jest dostępna dla dokumentów przesyłanych ręcznie.",
    "watch-desc-3": "Możesz zarządzać obserwowanymi dokumentami w",
    "file-manager": "menedżerze plików",
    "admin-view": "z poziomu panelu administratora",
    "pdr-add": "Dodano wszystkie dokumenty do Parent Document Retrieval",
    "pdr-remove": "Usunięto wszystkie dokumenty z Parent Document Retrieval",
    empty: "Nie znaleziono dokumentów",
  },
  "legal-question": {
    "category-one": "Kategoria Pierwsza",
    "category-two": "Kategoria Druga",
    "category-three": "Kategoria Trzecia",
    "category-four": "Kategoria Czwarta",
    "category-five": "Kategoria Piąta",
    "item-one": "Elementy Pierwsze",
    "item-two": "Elementy Drugie",
    "item-three": "Elementy Trzecie",
    "item-four": "Elementy Czwarte",
    "item-five": "Elementy Piąte",
    "item-six": "Elementy Szóste",
    "item-seven": "Elementy Siódme",
    "example-title":
      "Jedz dobrze: Przewodnik po czerpaniu przyjemności z jedzenia",
    example: {
      breakfast: {
        title: "1. Zdrowe opcje śniadaniowe",
        items: [
          "Owsianka ze świeżymi owocami i miodem",
          "Jogurt grecki z granolą i owocami",
          "Tost z awokado i jajkiem w koszulce",
          "Zielony koktajl ze szpinakiem, bananem i mlekiem migdałowym",
          "Pełnoziarniste naleśniki z syropem klonowym",
        ],
      },
      lunch: {
        title: "2. Szybkie i proste pomysły na lunch",
        items: [
          "Tortilla z kurczakiem i mieszanką sałat",
          "Sałatka z quinoą i pieczonymi warzywami",
          "Kanapka z indykiem na pieczywie pełnoziarnistym",
          "Smażone warzywa z brązowym ryżem",
          "Zestaw zupy z sałatką",
        ],
      },
      dinner: {
        title: "3. Pyszne przepisy na obiad",
        items: [
          "Pieczony łosoś z cytryną i szparagami",
          "Spaghetti z sosem pomidorowym i pulpecikami",
          "Warzywne curry z ryżem basmati",
          "Grillowany stek z pieczonymi ziemniakami",
          "Faszerowane papryki z quinoą i serem",
        ],
      },
    },
  },
  azure: {
    "service-endpoint": "Azure Service Endpoint",
    "service-endpoint-placeholder": "https://my-azure.openai.azure.com",
    "api-key": "Klucz API",
    "api-key-placeholder": "Klucz API Azure OpenAI",
    "chat-deployment": "Nazwa wdrożenia czatu",
    "chat-deployment-placeholder": "Nazwa wdrożenia modelu czatu Azure OpenAI",
    "token-limit": "Limit tokenów dla modelu czatu",
  },
  koboldcpp: {
    "show-advanced": "Pokaż ręczne wprowadzanie endpointu",
    "hide-advanced": "Ukryj ręczne wprowadzanie endpointu",
    "base-url": "Adres URL KoboldCPP",
    "base-url-placeholder": "http://127.0.0.1:5000/v1",
    "base-url-desc": "Wprowadź adres URL, pod którym działa KoboldCPP.",
    "auto-detect": "Auto-wykrywanie",
  },
  presets: {
    "edit-title": "Edytuj Preset",
    description: "Opis",
    "description-placeholder": "Odpowiada w formie wiersza o LLM.",
    deleting: "Usuwanie...",
    "delete-preset": "Usuń Preset",
    cancel: "Anuluj",
    save: "Zapisz",
    "add-title": "Dodaj Preset",
    "command-label": "Komenda",
    "command-placeholder": "/law",
    "command-desc": "Komenda uruchamiająca ten preset.",
    "prompt-label": "Prompt",
    "prompt-placeholder": "Napisz wiersz o LLM.",
    "prompt-desc": "Prompt, który zostanie wysłany po użyciu tej komendy.",
  },
  security: {
    "multi-user-mode-permanent":
      "Tryb wieloużytkownikowy jest trwale włączony ze względów bezpieczeństwa",
  },

  "legal-categories": {
    process: "Postępowanie",
    "process-stamning": "Pozew",
    "process-svaromal": "Odpowiedź na pozew",
    "process-yrkanden": "Żądania i przedstawienie stanu faktycznego",

    avtal: "Umowa",
    "avtal-anstallning": "Umowa o pracę",
    "avtal-finansiering": "Umowa finansowania i zabezpieczenia",
    "avtal-licens": "Umowa licencyjna",

    "due-diligence": "Due diligence",
    "due-diligence-avtal": "Analiza umów",
    "due-diligence-checklista": "Lista kontrolna due diligence",
    "due-diligence-compliance": "Kontrola zgodności",
  },
};

export default TRANSLATIONS;
